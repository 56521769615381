import {
  Button,
  Stack,
  useEventCallback,
  MoreHorizontalIcon,
} from '@mtb/ui';
import { useContextMenu } from '../../../../menus';
import { useStorageExplorerTable } from '../hooks';
import { useStorageExplorerTableRow } from '../StorageExplorerTableRow';
import { StorageExplorerTableCheckedOutUserTooltip } from './StorageExplorerTableCheckedOutUserTooltip';

export const StorageExplorerTableRowActions = () => {
  const { folder } = useStorageExplorerTableRow();
  const { isLoading } = useStorageExplorerTable();
  const { onContextMenu } = useContextMenu();

  const handleOnClick = useEventCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      onContextMenu(event);
    },
    [onContextMenu],
  );

  if (isLoading || folder) {
    return null;
  }

  return (
    <Stack
      align="center"
      direction="row"
      gap={1}
      justify="flex-end">
      <StorageExplorerTableCheckedOutUserTooltip />
      <Button
        color="default"
        data-testid="more-options-button-row-action"
        icon={<MoreHorizontalIcon size="small" />}
        variant="text"
        onClick={handleOnClick} />
    </Stack>
  );
};

export default StorageExplorerTableRowActions;
