import { useCallback, useMemo, useRef } from 'react';
import { Fade, TableRow } from '@mtb/ui';
import { useStorageExplorerItem } from '../../hooks';
import { useStorageExplorerTable } from '../hooks';
import { StorageExplorerTableContextMenu } from '../StorageExplorerTableContextMenu';
import { StorageExplorerTableRowCell } from '../StorageExplorerTableRowCell';
import { StorageExplorerTableRowContext } from './context';
import { useInViewTableRow } from './hooks';

export const StorageExplorerTableRow = ({
  row: rowProp,
  children,
  ...other
}) => {
  const { columns } = useStorageExplorerTable();
  const item = useStorageExplorerItem(rowProp);
  const actions = useRef();
  const [tableRowRef, inView] = useInViewTableRow();

  const handleOnKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        item.open();
      }
    },
    [item],
  );

  const handleOnClick = useCallback(
    (event) => {
      switch (event.detail) {
        // Single Click
        case 1:
          item.select();
          break;
        // Double Click
        default:
          item.open();
          break;
      }
    },
    [item],
  );

  const row = useMemo(
    () => ({
      type                   : item.type,
      id                     : item.id,
      driveId                : item.driveId,
      name                   : item.name,
      extension              : item.extension,
      icon                   : item.icon,
      folder                 : item.folder,
      createdBy              : item.createdBy,
      parentFolderUrl        : item.parentFolderUrl,
      lastAccessedElapsedTime: item.lastAccessedElapsedTime,
    }),
    [item],
  );

  return (
    <StorageExplorerTableRowContext.Provider value={row}>
      <StorageExplorerTableContextMenu action={actions}>
        <Fade in={inView}>
          <TableRow
            ref={tableRowRef}
            data-testid="storage-explorer-table-row"
            hover
            selected={item?.isSelected}
            tabIndex={0}
            onClick={handleOnClick}
            onKeyDown={handleOnKeyDown}
            {...other}>
            {children}
            {!children &&
              columns.map((column) => (
                <StorageExplorerTableRowCell
                  key={column.id}
                  column={column}
                  row={row} />
              ))}
          </TableRow>
        </Fade>
      </StorageExplorerTableContextMenu>
    </StorageExplorerTableRowContext.Provider>
  );
};
