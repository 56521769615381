import { CLOUD_STORAGE_API_URL } from './constants';
import configStore from './services/config';
import FetchWrapper from './services/fetch-wrapper';

/**
 * Extracts the stabilized API object from the connection object.
 * @param any connection - The connection information related to the project we are saving.
 * @returns {import('@').CloudStorageConnection} The stabilized connection object.
 */
const extractApiConnection = (connection) => {
  return {
    type        : connection.type,
    itemId      : connection.itemId,
    driveId     : connection.driveId,
    accessToken : connection.accessToken,
    refreshToken: connection.refreshToken,
  };
};

/**
 * API Request to set the connection for a project.
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be closed.
 * @param {import('@').CloudStorageItemConnectionWithTokens} connection - The connection information related to the project we are saving.
 * @returns
 */
export const setProjectConnection = async (projectId, connection) => {
  try {
    await FetchWrapper.put(
      `${CLOUD_STORAGE_API_URL}/${projectId}/connection`,
      {},
      {},
      extractApiConnection(connection),
    );
    return true;
  } catch {
    return false;
  }
};

export const createProject = async (connection) => {
  try {
    const { data } = await FetchWrapper.post(
      `${CLOUD_STORAGE_API_URL}/project`,
      {},
      {},
      connection ? extractApiConnection(connection) : {},
    );
    return {
      id            : data.id,
      autoSaveStatus: data.autosaveStatus?.toLowerCase(),
      cloudStatus   : data.cloudStatus?.toLowerCase(),
    };
  } catch {
    return false;
  }
};

export const uploadProjectContent = async (projectId, file) => {
  try {
    const payload = new FormData();
    payload.append('file', file);
    await FetchWrapper.put(
      `${CLOUD_STORAGE_API_URL}/${projectId}`,
      {},
      {},
      payload,
    );
    return true;
  } catch {
    return false;
  }
};

export const checkInProject = async (projectId) => {
  try {
    await FetchWrapper.put(`${CLOUD_STORAGE_API_URL}/${projectId}/checkin`);
    return true;
  } catch {
    return false;
  }
};

export const checkOutProject = async (projectId, force) => {
  try {
    // dont want to ignore retries, just handle 409 responses
    await FetchWrapper.put({
      resource: `${CLOUD_STORAGE_API_URL}/${projectId}/checkout`,
      params  : { force },
      retries : false,
    });
    return true;
  } catch {
    return false;
  }
};

/**
 * API Request to open a project from the server using a connection.
 * @param {import('@').CloudStorageItemConnectionWithTokens} connection - The connection information related to the item we are opening.
 * @returns {Promise<import('@').CloudStorageCreateProjectResponse>|Promise<boolean>} The response from the API request.
 */
export const openProjectFromConnection = async (connection) => {
  try {
    const { data } = await FetchWrapper.post(
      `${CLOUD_STORAGE_API_URL}/project/connection`,
      {},
      {},
      extractApiConnection(connection),
    );
    return {
      id            : data.id,
      autoSaveStatus: data.autosaveStatus?.toLowerCase(),
      cloudStatus   : data.cloudStatus?.toLowerCase(),
    };
  } catch (e) {
    return false;
  }
};

/**
 * API Request to open a passthrough item from the server using a connection.
 * @param {import('@').CloudStorageItemConnectionWithTokens} connection - The connection information related to the item we are opening.
 * @returns {Promise<import('@').CloudStorageProjectId>|Promise<boolean>} The response from the API request.
 */
export const openPassthroughItemFromConnection = async (connection) => {
  try {
    const { data } = await FetchWrapper.post(
      `${CLOUD_STORAGE_API_URL}/open/passthrough`,
      {},
      {},
      extractApiConnection(connection),
    );
    return data.project;
  } catch (e) {
    return false;
  }
};

/**
 * API Request to open a project from a file.
 * @param {File} file - The project item to be opened.
 * @param {import('@').CloudStorageItemConnectionWithTokens} connection - The connection information related to the item we are opening.
 * @returns {Promise<import('@').CloudStorageCreateProjectResponse>|Promise<boolean>} The response from the API request.
 */
export const openProjectFromFile = async (file, connection) => {
  try {
    const payload = new FormData();
    payload.append('file', file);
    if (connection) {
      payload.append(
        'connection',
        JSON.stringify(extractApiConnection(connection)),
      );
    }
    const { data } = await FetchWrapper.post(
      `${CLOUD_STORAGE_API_URL}/open`,
      {},
      {},
      payload,
    );
    return {
      id            : data.id,
      autoSaveStatus: data.autosaveStatus?.toLowerCase(),
      cloudStatus   : data.cloudStatus?.toLowerCase(),
    };
  } catch {
    return false;
  }
};

/**
 * API Request to open a passthrough item from a file.
 * @param {File} file - The passthrough item to be opened.
 * @returns {Promise<import('@').CloudStorageProjectId>|Promise<boolean>} The response from the API request.
 */
export const openPassthroughItemFromFile = async (file) => {
  try {
    const payload = new FormData();
    payload.append('file', file);
    const { data } = await FetchWrapper.post(
      `${CLOUD_STORAGE_API_URL}/passthrough`,
      {},
      {},
      payload,
    );
    return data?.project;
  } catch {
    return false;
  }
};

/**
 * API Request to close a project.
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be closed.
 * @returns {Promise<boolean>} The response from the API request.
 */
export const closeProject = async (projectId) => {
  try {
    await FetchWrapper.get(`${CLOUD_STORAGE_API_URL}/${projectId}/stale`);
    return true;
  } catch {
    return false;
  }
};

/**
 * Retrieves information about the project from the API.
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be retrieved.
 * @returns {Promise<import('@').CloudStorageProjectInfoResponse} The project info.
 */
export const getProjectInfo = async (projectId) => {
  const { data } = await FetchWrapper.get({
    resource: `${CLOUD_STORAGE_API_URL}/${projectId}/info`,
    retries : false,
  });

  const connection = data.connection
    ? {
      type   : data.connection.type,
      itemId : data.connection.itemId,
      driveId: data.connection.driveId,
    }
    : null;

  return {
    autoSaveStatus: data.autosaveStatus?.toLowerCase(),
    cloudStatus   : data.cloudStatus?.toLowerCase(),
    connection,
  };
};

/**
 * Invokes a flush from cloud storage to the cloud provider
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be retrieved.
 * @returns {Promise<boolean>} The success of the API request.
 */
export const flushProject = async (projectId) => {
  try {
    await FetchWrapper.post(`${CLOUD_STORAGE_API_URL}/${projectId}/flush`);
    return true;
  } catch {
    return false;
  }
};

/**
 * Sets the autosave state for a project.
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the project for which autosave is enabled.
 * @param {import('@').AutoSaveStatus} autoSaveState - The autosave state to be set.
 * @returns {Promise<boolean>} The response from the API request.
 */
export const setProjectAutoSave = async (projectId, autoSaveState) => {
  try {
    await FetchWrapper.put(
      `${CLOUD_STORAGE_API_URL}/${projectId}/autosave?autoSaveState=${autoSaveState}`,
    );
    return true;
  } catch {
    return false;
  }
};

export const getProjectDownloadUrl = (projectId, fileName) => {
  return `${CLOUD_STORAGE_API_URL}/${projectId}?fileName=${fileName}`;
};

/**
 * Refreshes the user's token set for the cloud storage provider.
 * @param {string} type - The type of token to refresh. [google|onedrive]
 * @param {string} refreshToken - The refresh token to use.
 */
export async function refreshToken(type, refreshToken) {
  return (
    await FetchWrapper.post(
      configStore.config.cloud_storage_token_url,
      {},
      {},
      { type, refreshToken },
      'json',
      {
        mode: 'cors',
      },
    )
  ).data;
}

const api = {
  checkOutProject,
  createProject,
  getProjectInfo,
  openProjectFromConnection,
  setProjectConnection,
  uploadProjectContent,
};

export default api;
