import type { StoreProjectConnection } from '../../store/projects/types';
import type {
  ConnectionService,
  GetItemByIdOptions,
  InterceptedAuthResponse,
  StorageProviderDriveId,
  StorageProviderItem,
  StorageProviderItemConnection,
  StorageProviderItemId,
  StorageProviderKey,
  StorageProviderUser,
} from '@';
import { getIsItemRoot, getProviderServiceByType } from '../../utils';

const getProvider = (
  type: StorageProviderKey,
): ConnectionService<StorageProviderKey> => {
  const provider = getProviderServiceByType(type);
  if (!provider) {
    throw new Error(`Provider ${type} not found`);
  }
  return provider;
};

class ProviderClient {
  /**
   * Validates the authentication for a provider.
   * @param type - The provider type.
   * @returns - Whether the provider auth state is valid.
   */
  async validateAuth(type: StorageProviderKey): Promise<boolean> {
    try {
      await getProvider(type).api.getAccount();
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Logs in to the provider.
   * @param type - The provider type.
   * @returns - A promise that resolves when the login is complete.
   */
  login(type: StorageProviderKey): Promise<void> {
    return getProvider(type)?.login();
  }

  /**
   * Logs out of the provider.
   * @param type - The provider type.
   * @returns - A promise that resolves when the logout is complete.
   */
  logout(type: StorageProviderKey): Promise<void> {
    return getProvider(type)?.logout();
  }

  /**
   * Fetches the account information for the current provider.
   * @param type - The provider type.
   * @param response - The response from the provider.
   * @returns - The account information.
   **/
  getAccount(
    type: StorageProviderKey,
    response: InterceptedAuthResponse,
  ): Promise<StorageProviderUser | null> {
    return getProvider(type)?.api.getAccount(false, response);
  }

  /**
   * Fetches the account picture for the current provider.
   * @param type - The provider type.
   * @returns - A DataURL string of the account picture.
   **/
  async getAccountPicture(type: StorageProviderKey): Promise<string> {
    const picture = await getProvider(type)?.api.getAccountPicture();
    if (!picture) {
      return '';
    }

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (typeof reader.result !== 'string') {
          reject();
          return;
        }
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(picture);
    });
  }

  /**
   * Gets the children of a folder from the storage service API.
   * @param folderId - The folder to get the children for.
   * @param signal - A signal used to cancel the request
   */
  getChildren(
    type: StorageProviderKey,
    folder: StorageProviderItem,
    signal: AbortSignal | undefined,
  ): Promise<StorageProviderItem[] | null> {
    const provider = getProvider(type);
    if (folder instanceof AbortSignal) {
      signal = folder;
      folder = 'root' as unknown as StorageProviderItem;
    }
    return getIsItemRoot(folder)
      ? provider.api.getRootChildren(signal)
      : provider.api.getFolderChildren(folder.id, folder.driveId, signal);
  }

  /**
   * Gets the recent items from the storage service API.
   * @param type - The provider type.
   * @param filter - The filter parameters.
   * @param signal - A signal used to cancel the request
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  getRecent(
    type: StorageProviderKey,
    filter: string[],
    signal: AbortSignal,
  ): Promise<StorageProviderItem[] | null> {
    if (filter instanceof AbortSignal) {
      signal = filter;
      filter = [];
    }
    return getProvider(type).api.getRecent(filter, signal);
  }

  /**
   * Gets the shared items from the storage service API.
   * @param type - The provider type.
   * @param folder - The folder to get the shared items for.
   * @param signal - A signal used to cancel the request
   */
  getShared(
    type: StorageProviderKey,
    folder: StorageProviderItem,
    signal: AbortSignal,
  ): Promise<StorageProviderItem[] | null> {
    if (folder instanceof AbortSignal) {
      signal = folder;
      folder = 'root' as unknown as StorageProviderItem;
    }

    if (getIsItemRoot(folder)) {
      return getProvider(type).api.getShared(signal);
    }

    return this.getChildren(type, folder, signal);
  }

  /**
   * Gets an item from the provider service API.
   * @param type - The provider type.
   * @param id - The ID of the item to retrieve.
   * @param driveId - The ID of the drive the item belongs to.
   * @param options - Additional options for the API call.
   * @returns - The item retrieved by ID.
   */
  getItemById(
    type: StorageProviderKey,
    id: StorageProviderItemId,
    driveId: StorageProviderDriveId,
    options: GetItemByIdOptions,
  ): Promise<StorageProviderItem | null> {
    const { cache = true, params = {} } = options;
    const paramStr = params ? JSON.stringify(params) : '';
    return getProvider(type).api.getItemById(id, driveId, cache, paramStr);
  }

  /**
   * Searches for an item using the storage service API.
   * @param query - The query to search for.
   * @param maxResults - The maximum number of results to return.
   * @param options - Additional options to pass to the API.
   */
  searchItem(
    type: StorageProviderKey,
    query: string,
    maxResults: number,
    options: object,
  ): Promise<StorageProviderItem[] | null> {
    const optionsStr = options ? JSON.stringify(options) : '';
    return getProvider(type).api.searchItem(query, maxResults, optionsStr);
  }

  /**
   * Creates an item in the cloud storage provider's repository.
   * @param item - The item information used to create the connection.
   * @returns The connection information for the uploaded item.
   */
  createItem(
    type: StorageProviderKey,
    name: string,
  ): Promise<StorageProviderItemConnection> {
    return getProvider(type).api.createItemForConnection(name);
  }

  /**
   * Returns the remaining storage space for the current provider.
   */
  getRemainingStorageSpace(type: StorageProviderKey): Promise<number> {
    return getProvider(type).api.getRemainingStorageSpace();
  }

  renameItem(
    connection: StoreProjectConnection,
    newName: string,
  ): Promise<string> {
    if (!connection.itemId || !connection.driveId) {
      throw new Error('Item ID or Drive ID is undefined.');
    }

    return getProvider(connection.type).api.renameItem(
      connection.itemId,
      connection.driveId,
      newName,
    );
  }
}

const client = new ProviderClient();

export default client;
