import type { ModuleConfig } from '../types';
import config from '../../../config';
import { RemoteReportingTool, loadRemoteReportingTool } from './remote';

const CONFIG: ModuleConfig = {
  key      : 'REPORTING_TOOL',
  title    : 'reportingtool',
  icon     : 'MinitabConnectIcon',
  featureId: '7a218321eda941a4bc9590c088746be0',
  card     : {
    title         : 'reportingtool',
    description   : 'reportingToolDescription',
    tagline       : 'report',
    icon          : 'MinitabConnectIcon',
    heroIcon      : 'ReportingToolHeroIcon',
    fillColor     : '#D7E9F0',
    hoverFillColor: '#BDDBE6',
  },
  preloadRemoteModule: loadRemoteReportingTool,
  remoteModule       : RemoteReportingTool,
  storage            : {
    defaultProjectName: 'reportingToolDefaultProject',
    defaultExtension  : '.mrpt',
    defaultPlaneState : { isDirty: false },
    filter            : ['.mrpt', ...(config.feature_flag_ui_v2 ? ['.csv', '.dat', '.xlsx'] : [])],
    passthroughFilter : ['.mtw', '.mwx', ...(config.feature_flag_ui_v2 ? ['.csv', '.dat', '.xlsx'] : [])],
  },
};

export default CONFIG;
