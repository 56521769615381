import { createContext } from 'react';

export const StorageExplorerTableRowContext = createContext({
  type                   : '',
  id                     : '',
  driveId                : '',
  name                   : '',
  extension              : '',
  icon                   : '',
  folder                 : false,
  createdBy              : '',
  parentFolderUrl        : '',
  lastAccessedElapsedTime: '',
});
