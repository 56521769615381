import { useMemo, useState } from 'react';
import { useDidMount } from '@mtb/ui';
import { useCloudStorageStore } from '../../../../../hooks';
import { useStorageExplorerTableRow } from '../../StorageExplorerTableRow';

export function useCheckoutUser() {
  const [isFetching, setIsFetching] = useState(true);
  const [checkoutUserName, setCheckoutUserName] = useState(false);
  const { getCheckoutUser } = useCloudStorageStore();
  const row = useStorageExplorerTableRow();

  useDidMount(() => {
    if (row.folder) {
      return;
    }
    let current = true;
    (async () => {
      const checkoutUser = await getCheckoutUser(row);
      if (!current) {
        return;
      }
      if (checkoutUser?.displayName) {
        setCheckoutUserName(checkoutUser.displayName);
      }
      setIsFetching(false);
    })();
    return () => {
      current = false;
    };
  });

  return useMemo(
    () => ({ isFetching, checkoutUserName }),
    [isFetching, checkoutUserName],
  );
}
