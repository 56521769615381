import type { InterceptedAuth } from './types';
import type { StorageProviderAutoSaveFolder, StorageProviderKey } from '@';
import type { StoreProvider } from 'lib/store/providers/types';
import DialogClient from '../../clients/dialog';
import ProviderClient from '../../clients/provider';
import { STORAGE_PROVIDER_KEYS } from '../../constants';
import { setOnRefreshTokenError } from '../../providers/ProviderBase/ProviderApi';
import ProviderStore from '../../store/providers';
import { updateLoginCounter } from '../store-utils';

class ProviderService {
  async validateProviders(): Promise<void> {
    const validations: Promise<void>[] = [];

    for (const provider of Object.values(ProviderStore.getProviders())) {
      validations.push(this.validateProvider(provider.type));
    }

    await Promise.all(validations);
  }

  signIn(key: StorageProviderKey): void {
    ProviderClient.login(key);
  }

  async validateProvider(key: StorageProviderKey): Promise<void> {
    const provider = ProviderStore.getProvider(key);
    if (!provider?.tokens) {
      return;
    }

    const authValid = await ProviderClient.validateAuth(key);
    if (!authValid) {
      this.signOut(key, true);
    }
  }

  async handleInterceptedAuth({ state, response }: InterceptedAuth) {
    const type = state.type;
    if (type === STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      updateLoginCounter();
    }

    const account = await ProviderClient.getAccount(type, response);
    if (!account) {
      return;
    }

    const provider: StoreProvider = {
      type,
      account: {
        id    : account.id,
        email : account.email ?? '',
        name  : account.name ?? '',
        avatar: account.picture ?? '',
      },
      tokens: {
        accessToken : response.access_token,
        refreshToken: response.refresh_token,
        expiresAt   : response.expires_in,
      },
      defaultSaveFolder: null,
    };

    ProviderStore.setProvider(type, provider);

    if (ProviderStore.getDefaultProvider() === null) {
      ProviderStore.setDefaultProvider(type);
    }

    // onedrive doesn't return pic on login, fetch it
    if (account.picture) {
      return;
    }

    const profilePic = await ProviderClient.getAccountPicture(type);
    provider.account.avatar = profilePic;
    ProviderStore.setProvider(type, provider);
  }

  async signOut(key: StorageProviderKey, disableInteraction = false): Promise<void> {
    if (!disableInteraction) {
      const confirmed = await DialogClient.confirmProviderSignout();
      if (!confirmed) {
        return;
      }
    }
    await ProviderClient.logout(key);
    ProviderStore.removeProvider(key);
    if (ProviderStore.getDefaultProvider() === key) {
      ProviderStore.removeDefaultProvider();
    }
  }

  async toggleDefaultProvider(key: StorageProviderKey): Promise<void> {
    const defaultProvider = ProviderStore.getDefaultProvider();
    if (!defaultProvider) {
      ProviderStore.setDefaultProvider(key);
    } else if (defaultProvider === key) {
      ProviderStore.removeDefaultProvider();
    } else {
      const confirmed = await DialogClient.confirmDefaultSaveChange();
      if (confirmed) {
        ProviderStore.setDefaultProvider(key);
      }
    }
  }

  setDefaultSaveFolder(
    key: StorageProviderKey,
    folder: StorageProviderAutoSaveFolder | null,
  ): void {
    const provider = ProviderStore.getProvider(key);
    if (!provider) {
      return;
    }
    provider.defaultSaveFolder = folder;
    ProviderStore.setProvider(key, provider);
  }
}

const providerService = new ProviderService();

setOnRefreshTokenError((type: StorageProviderKey) => {
  providerService.signOut(type);
});

export default providerService;
