import type { I18nLocale } from '../../../clients';
import { AuthenticationClient, LoggerClient } from '../../../clients';
import { platformStore } from '../../store';

class UserService {
  #logger = LoggerClient.createNamedLogger('UserService');

  /**
   * Validates the user's claims.
   * @returns True if the user's claims are valid, false otherwise.
   */
  async validate(): Promise<void> {
    await platformStore.actions.validateUser();
  }

  /**
   * Updates the user's locale.
   * @param locale
   */
  updateLocale(locale: Partial<I18nLocale>) {
    platformStore.actions.updateLocale(locale);
  }

  /**
   * Opens the account management window.
   */
  manageAccount() {
    const claims = platformStore.getState().user.claims;
    AuthenticationClient.manageAccount(claims);
  }

  /**
   * Logs the user out.
   */
  async logout() {
    await platformStore.actions.logoutUser();
  }
}

export default UserService;
