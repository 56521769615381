import React, { useState } from 'react';
import {
  MenuItem,
  ListItemText,
  Menu,
  Button,
  Stack,
  useEventCallback,
  MicrosoftOneDriveIcon,
  GoogleDriveIcon,
} from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';

export const StorageProjectMenuSelectAutosaveProvider = () => {
  const [t] = useTranslation('connection');

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = useEventCallback((e) => setAnchorEl(e.currentTarget));
  const handleCloseMenu = useEventCallback(() => setAnchorEl(null));

  return (
    <>
      <Button
        data-testid="choose-autosave-provider-button"
        variant="contained"
        onClick={handleOpenMenu}>
        {t('connection.enableAutosave')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical  : 'bottom',
          horizontal: 'center',
        }}
        data-testid="choose-autosave-provider-menu"
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}>
        <MenuItem onClick={() => console.log('enable onedrive autosaving / login')}>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <MicrosoftOneDriveIcon />
            <ListItemText primary={t('connection.oneDrive')} />
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => console.log('enable onedrive autosaving / login')}>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <GoogleDriveIcon />
            <ListItemText primary={t('connection.googleDrive')} />
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
