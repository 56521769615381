import type { PlatformModuleBase } from './types';
import type { I18nLocale } from '../../clients';
import type { UserSettings } from '../store/reducers/user/types';
import { useMemo } from 'react';
import PlatformCore from '..';

/**
 * StaticPlatformModule is the base class that is given to a remote module when we go to render their remote content.
 * It represents a fully configured module instance that has the ability and access to interact with Platform but does not
 * include any of the additional controls around interacting with a Plane. This class serves as a centralized location to
 * expose a public API for all "planeless" remote module interactions where we can control and manage what interactions we expose.
 */
export class StaticPlatformModule implements PlatformModuleBase {
  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return PlatformCore.selectors.settings(PlatformCore.getState());
  }

  /**
   * Get user locale.
   */
  get locale(): I18nLocale {
    return PlatformCore.selectors.locale(PlatformCore.getState());
  }

  /**
   * Uses a selector for claims.
   */
  useClaims(): unknown {
    const claims = PlatformCore.useSelector(state => PlatformCore.selectors.claims(state));
    const transformedClaims = useMemo(() => claims, [claims]);
    return transformedClaims;
  }

  /**
   * Uses a selector to get the user's locale settings.
   * @returns The locale settings.
   */
  useLocale(): I18nLocale | undefined {
    return PlatformCore.useSelector(state => PlatformCore.selectors.locale(state));
  }
}

export const staticPlatformModule = new StaticPlatformModule();
export default staticPlatformModule;
