/* eslint-disable @typescript-eslint/no-unused-vars */
import type { IntegratedPlatformModule } from '../platform-module/types';
import type { Plane } from '../store/reducers/planes';
import type { CloudStoragePassthroughItem, CloudStorageProjectItem } from '@mtb/cloud-storage/types';

/**
 * Represents the events configuration for a platform remote module.
 */
class RemoteModuleConfigEvents {
  /**
   * Flag to enable passing the plane's PlatformModule instance instead of the plane itself.
   */
  #platformModuleArg = false;

  set platformModuleArg(value: boolean) {
    this.#platformModuleArg = value;
  }

  get platformModuleArg() {
    return this.#platformModuleArg;
  }

  onPulse = (_planeOrPlatformModule: Plane | IntegratedPlatformModule) => Promise.resolve();
  onCleanup = (_planeOrPlatformModule: Plane | IntegratedPlatformModule) => Promise.resolve();
  onClose = (_planeOrPlatformModule: Plane | IntegratedPlatformModule) => Promise.resolve();
  onFlush = (_planeOrPlatformModule: Plane | IntegratedPlatformModule) => Promise.resolve();
  onOpen = (
    _planeOrPlatformModule: Plane | IntegratedPlatformModule,
    _fileInfo: CloudStoragePassthroughItem | CloudStorageProjectItem,
  ) => Promise.resolve();
}

export default RemoteModuleConfigEvents;
