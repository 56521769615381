/* eslint-disable no-unused-vars */
import { refreshToken } from '../../api';
import configStore from '../../services/config';
import ProviderStore from '../../store/providers';
import { authorizedApiCall } from '../utils';

// Handler that CloudStorage store will assign to onRefreshTokenError
// in module scope when the store is initialized.
let onRefreshTokenError = (type) => undefined;
export const setOnRefreshTokenError = (fn) => {
  onRefreshTokenError = fn;
};

class ProviderApi {
  /** @type {import('@').LoginFunction} */
  _login = () => undefined;

  /**
   * @param {{ login: import('@').LoginFunction }} options
   */
  constructor({ login }) {
    this._login = login;
  }

  /**
   * @returns {import('@').AuthTokens}
   **/
  getProviderAuthTokens() {
    const provider = ProviderStore.getProvider(this._connectionType);
    if (!provider?.tokens) {
      throw new Error('No provider account tokens');
    }
    return {
      access_token : provider.tokens.accessToken,
      refresh_token: provider.tokens.refreshToken,
    };
  }

  setProviderAuthTokens(tokens) {
    const provider = ProviderStore.getProvider(this._connectionType);
    provider.tokens = {
      accessToken : tokens.access_token,
      refreshToken: tokens.refresh_token,
    };
    ProviderStore.setProvider(this._connectionType, { provider });
  }

  /**
   * @this {import('.').ProviderBase}
   * @template {import('@mtb/utilities/dist/types/FetchWrapper').HttpVerbs} Verb
   * @template {import('@mtb/utilities/dist/types/FetchWrapper').ResponseBodyTypes} [Type="json"]
   * @param {import('../utils').RequestConfig<Verb, Type>} config
   * @returns {Promise<import('@mtb/utilities/dist/types/FetchWrapper').RequestResponseBody<Type>>}
   */
  async makeApiCall(config) {
    const tokens = configStore.config.feature_flag_cs_store_v2
      ? this.getProviderAuthTokens()
      : this.getCachedAuthTokens();
    try {
      const result = await authorizedApiCall.call(this, config, tokens);
      if (config?.signal?.aborted) {
        return null;
      }
      return result;
    } catch (e) {
      if (config?.signal?.aborted) {
        return null;
      }
      if (this.didRecentlyLogOut()) {
        // eslint-disable-next-line no-throw-literal
        throw { loggedOut: true };
      }

      if (e.needsAuthentication) {
        try {
          if (!tokens.refresh_token) {
            throw new Error('Did not have refresh token, need to reauth.');
          }
          const refreshedTokens = await refreshToken(
            this._connectionType,
            tokens.refresh_token,
          );
          if (configStore.config.feature_flag_cs_store_v2) {
            this.setProviderAuthTokens(refreshedTokens);
          } else {
            this.setCacheTokens(refreshedTokens);
          }
          return await authorizedApiCall.call(this, config, refreshedTokens);
        } catch {
          this.clearCache();
          onRefreshTokenError(this._connectionType);
          return;
        }
      } else if (e.needsTimedRetry) {
        await new Promise((res) => setTimeout(res, 150));
        return await this.makeApiCall(config);
      }

      throw e;
    }
  }

  /**
   * @this {import('.').ProviderBase}
   * @template {import('@mtb/utilities/dist/types/FetchWrapper').HttpVerbs} Verb
   * @template {import('@mtb/utilities/dist/types/FetchWrapper').ResponseBodyTypes} [Type="json"]
   * @param {import('../utils').RequestConfig<Verb, Type>} baseConfig
   * @returns {Promise<import('@mtb/utilities/dist/types/FetchWrapper').RequestResponseBody<Type>>}
   */
  wrappedApiCall(baseConfig) {
    if (this.didRecentlyLogOut()) {
      // eslint-disable-next-line no-throw-literal
      throw { loggedOut: true };
    }
    return this.makeApiCall(baseConfig);
  }

  /**
   * @returns {string}
   */
  getRootWebUrl() {
    throw new Error('Unimplemented interface method getRootWebUrl');
  }

  /**
   * @returns {Promise<string>}
   */
  async _getRootInfo() {
    throw new Error('Unimplemented interface method _getRootInfo');
  }

  /**
   * @param {string | {}} idOrConnection
   * @param {string} driveId
   * @returns {Promise<string>}
   */
  async _getShareUrl(idOrConnection, driveId) {
    throw new Error('Unimplemented interface method _getShareUrl');
  }

  /**
   * @param {string} id
   * @param {string} driveId
   * @param {string} name
   * @returns {Promise<string>}
   */
  async renameItem(id, driveId, name) {
    throw new Error('Unimplemented interface method renameItem');
  }

  /**
   * @param {string} id
   * @param {string} driveId
   * @returns {Promise<import('@').StorageProviderItem[]>}
   */
  async getBreadcrumbs(id, driveId) {
    throw new Error('Unimplemented interface method getBreadcrumbs');
  }

  /**
   * @param {string} id
   * @param {string} driveId
   * @returns {Promise<import('@').StorageProviderItem[]>}
   */
  async getFolderBreadcrumbs(id, driveId) {
    throw new Error('Unimplemented interface method getFolderBreadcrumbs');
  }

  /**
   * @param {string} name
   * @returns {Promise<import('@').StorageProviderItem>}
   */
  async createDefaultItem(name) {
    throw new Error('Unimplemented interface method createDefaultItem');
  }

  /**
   * @param {string} name
   * @param {string} folder
   * @param {'fail' | 'replace' | 'rename'} [conflictBehavior="rename"]
   * @returns {Promise<import('@').StorageProviderItem>}
   */
  async createInFolder(name, folder, conflictBehavior = 'rename') {
    throw new Error('Unimplemented interface method createInFolder');
  }

  /**
   * @param {boolean} [skipAuthRetry=false]
   * @param {Parameters<typeof authorizedApiCall>['1']} [auth=undefined]
   * @returns {Promise<import('@').StorageProviderUser>}
   */
  async getAccount(skipAuthRetry = false, auth = undefined) {
    throw new Error('Unimplemented interface method getAccount');
  }

  /**
   * @returns {Promise<Blob>}
   */
  async getAccountPicture() {
    throw new Error('Unimplemented interface method getAccountPicture');
  }

  /**
   * @param {string} [field='*']
   * @returns {Promise<import('@').StorageProviderItemAbout>}
   */
  async getAbout(field = '*') {
    throw new Error('Unimplemented interface method getAbout');
  }

  /**
   * @param {AbortSignal} [signal]
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  async getRootChildren(signal) {
    throw new Error('Unimplemented interface method getRootChildren');
  }

  /**
   * @param {string[]} [overrideFilter]
   * @param {AbortSignal} [signal]
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  async getRecent(overrideFilter, signal) {
    throw new Error('Unimplemented interface method getRecent');
  }

  /**
   * @param {AbortSignal} [signal]
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  async getShared(signal) {
    throw new Error('Unimplemented interface method getShared');
  }

  /**
   * @param {string} folderId
   * @param {string} driveId
   * @param {AbortSignal} [signal]
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  async getFolderChildren(folderId, driveId, signal) {
    throw new Error('Unimplemented interface method getFolderChildren');
  }

  /**
   * @param {string} driveId
   * @param {string} id
   * @param {boolean} [useCache=true]
   * @param {string} [params='?expand=listItem']
   * @returns {Promise<import('@').StorageProviderItem>}
   */
  async getItemById(id, driveId, useCache = true, params = '?expand=listItem') {
    throw new Error('Unimplemented interface method getItemById');
  }

  /**
   * @param {string} text
   * @param {number} [pageSize=25]
   * @param {string} [options='']
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  async searchItem(text, pageSize = 25, options = '') {
    throw new Error('Unimplemented interface method searchItem');
  }

  /**
   * @param {string} principalName
   * @param {string} [params='']
   * @returns {Promise<import('@').StorageProviderUser>}
   */
  async getUser(principalName, params = '') {
    throw new Error('Unimplemented interface method getUser');
  }

  /**
   * @param {string} principalName
   * @returns {Promise<Blob>}
   */
  async getUserPhoto(principalName) {
    throw new Error('Unimplemented interface method getUserPhoto');
  }

  /**
   * @param {import('@').StorageProviderItem} item
   * @returns {Promise<import('@').StorageProviderCheckoutUser | null>}
   */
  async getCheckoutUser(item) {
    throw new Error('Unimplemented interface method getCheckoutUser');
  }

  /**
   * @param {string} id
   * @param {string} driveId
   * @param {string} parentId
   * @returns {Promise<boolean>}
   */
  async moveItem(id, driveId, parentId) {
    throw new Error('Unimplemented interface method moveItem');
  }

  /**
   * @param {import('@').StorageProviderItem} item
   * @param {string} [name]
   * @returns {Promise<void>}
   */
  async duplicateItem(item, name) {
    throw new Error('Unimplemented interface method duplicateItem');
  }

  /**
   * @param {import('@').StorageProviderItem} item
   * @returns {Promise<void>}
   */
  async deleteItem(item) {
    throw new Error('Unimplemented interface method deleteItem');
  }

  /**
   * @param {import('@').StorageProviderItem} item
   * @returns {Promise<void>}
   */
  async checkInItem(item) {
    throw new Error('Unimplemented interface method checkInItem');
  }

  /**
   * Get the remaining storage space for the current provider.
   * @returns {Promise<number>} - The remaining storage space in bytes.
   */
  async getRemainingStorageSpace() {
    throw new Error('Unimplemented interface method getRemainingStorageSpace');
  }
}

export default ProviderApi;
