import { useMemo } from 'react';
import {
  useDidMount,
  useSessionStorage as useMtbUiSessionStorage,
} from '@mtb/ui';

/**
 * Creates a namespaced storage key consistent with the cloud storage module.
 * @param {string} noun - The noun to namespace the storage key.
 * @param {string} type - The storage provider type to namespace the storage key.
 * @param {string} id - The unique identifier for the storage key.
 * @returns {string} - The namespaced storage key.
 */
function createStorageKey(noun, id) {
  if (!noun) {
    throw new Error('Noun is required to create a storage key.');
  }
  return ['cloud-storage', noun, id].filter(Boolean).join('-');
}

/**
 * @typedef UseSessionStorageOptions
 * @property {string} noun - The noun to namespace the storage key.
 * @property {string} id - The unique identifier for the storage key.
 * @property {any} initialValue - The initial value for the storage key.
 * @property {boolean} [disableCache] - Whether to disable the cache.
 */

/**
 * A custom hook to store cloud storage state in session storage with a consistent storage key.
 * @param {UseSessionStorageOptions} options
 * @returns {[any, import('react').Dispatch<import('react').SetStateAction<any>>]}
 */
export function useSessionStorage({
  noun,
  id,
  initialValue,
  disableCache = false,
}) {
  const storageKey = useMemo(() => createStorageKey(noun, id), [noun, id]);
  const [value, setValue] = useMtbUiSessionStorage(storageKey, initialValue);

  useDidMount(() => {
    if (disableCache) {
      setValue(initialValue);
    }
  });

  return [value, setValue];
}
