import React, { useMemo } from 'react';
import {
  FormControl,
  FormLabel,
  Spacer,
  Stack,
  Divider,
} from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { StorageProjectMenuSelectAutosaveProvider } from '../StorageProjectMenuSelectAutosaveProvider';
import { StorageProjectMenuSignInButton } from '../StorageProjectMenuSignInButton';
import { AutosaveSwitch } from './AutosaveSwitch';

const AUTOSAVING_TITLES = {
  [STORAGE_PROVIDER_KEYS.ONE_DRIVE]   : 'connection.autoSavingOneDrive',
  [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: 'connection.autoSavingGoogleDrive',
  none                                : 'connection.autoSave',
};

export function StorageProjectMenuAutoSave() {
  const [t] = useTranslation();

  const isAnyProviderAuthed = useMemo(() => true, []); // TODO: get the actual value
  const hasAutosaveProvider = useMemo(() => true, []); // TODO: get the actual value

  const provider = useMemo(
    () => (
      hasAutosaveProvider
        ? STORAGE_PROVIDER_KEYS.ONE_DRIVE // TODO: get the actual provider
        : 'none'
    ),
    [hasAutosaveProvider],
  );

  const autosavingProviderTitle = useMemo(
    () => t(AUTOSAVING_TITLES[provider]),
    [t, provider],
  );

  return (
    <>
      <FormControl>
        {isAnyProviderAuthed ? (
          <Stack
            alignItems="center"
            direction="row">
            <FormLabel>{autosavingProviderTitle}</FormLabel>
            <Spacer />
            {hasAutosaveProvider ? (
              <AutosaveSwitch />
            ) : (
              <StorageProjectMenuSelectAutosaveProvider />
            )}
          </Stack>
        ) : (
          <StorageProjectMenuSignInButton />
        )}
      </FormControl>
      <Divider />
    </>
  );
}
