import type { CloudStorageProject, ConnectionInfo } from './types';
import type { StoreProvider } from '../../store/providers/types';
import type {
  CloudStorageCreateProjectResponse,
  StorageProviderItem,
  StorageProviderItemConnection,
  StorageProviderKey,
} from '@';
import type { StoreProject } from 'lib/store/projects/types';
import CloudStorageApi from '../../api';
import DialogClient from '../../clients/dialog';
import ProviderClient from '../../clients/provider';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  MINIMUM_STORAGE_SPACE_THRESHOLD,
  STORAGE_PROVIDER_KEYS,
} from '../../constants';
import ProjectStore from '../../store/projects';
import ProviderStore from '../../store/providers';
import { getNameParts } from '../../utils';

function getDefaultSaveProvider(): StoreProvider | null {
  const defaultProvider = ProviderStore.getDefaultProvider();
  return defaultProvider ? ProviderStore.getProvider(defaultProvider) : null;
}

function toExternalProject(project: StoreProject): CloudStorageProject {
  return {
    ...project,
    type          : project.connection.type,
    autoSaveStatus: project.connection.autoSaveStatus,
    cloudStatus   : project.connection.cloudStatus,
    operation     : project.operation ?? null,
    breadcrumbs   : [],
  };
}

function newStoreProject(): StoreProject {
  return {
    id         : '',
    name       : '',
    displayName: '',
    extension  : '',
    connection : {
      type          : STORAGE_PROVIDER_KEYS.LOCAL,
      itemId        : undefined,
      driveId       : undefined,
      autoSaveStatus: AUTO_SAVE_STATUS.NONE,
      cloudStatus   : CLOUD_STATUS.NONE,
    },
    operation: undefined,
  };
}

class ProjectService {
  async verifyStorageSpace(type: StorageProviderKey): Promise<void> {
    const remainingStorageSpace = await ProviderClient.getRemainingStorageSpace(
      type,
    );
    if (remainingStorageSpace < MINIMUM_STORAGE_SPACE_THRESHOLD) {
      DialogClient.alertOutOfStorage();
      throw new Error('Not enough storage space.');
    }
  }

  async createProject(file: File): Promise<CloudStorageProject> {
    if (!file.name) {
      throw new Error('File name is required.');
    }

    const provider = getDefaultSaveProvider();
    if (provider) {
      await this.verifyStorageSpace(provider.type);
    }

    let item: StorageProviderItemConnection | undefined;
    let connection: ConnectionInfo | undefined;

    if (provider) {
      item = await ProviderClient.createItem(provider.type, file.name);
      if (!item) {
        throw new Error('Failed to create item.');
      }

      connection = {
        itemId      : item.id,
        driveId     : item.driveId,
        type        : provider?.type,
        accessToken : provider?.tokens.accessToken,
        refreshToken: provider?.tokens.refreshToken,
      };
    }

    const projectInfo = await CloudStorageApi.createProject(connection);
    if (!projectInfo) {
      throw new Error('Failed to create project.');
    }

    const nameParts = getNameParts(item?.name || file.name);

    const project: StoreProject = {
      ...newStoreProject(),
      id         : projectInfo.id,
      name       : nameParts.name,
      displayName: nameParts.displayName,
      extension  : nameParts.extension,
      connection : {
        type          : provider?.type || STORAGE_PROVIDER_KEYS.LOCAL,
        itemId        : item?.id,
        driveId       : item?.driveId,
        autoSaveStatus: projectInfo.autoSaveStatus,
        cloudStatus   : projectInfo.cloudStatus,
      },
    };

    ProjectStore.setProject(project.id, project);

    let upload: Promise<boolean> | undefined;
    let checkout: Promise<boolean> | undefined;

    if (file.size > 0) {
      upload = CloudStorageApi.uploadProjectContent(project.id, file);
    }

    if (item) {
      checkout = CloudStorageApi.checkOutProject(project.id, false);
    }

    await Promise.all([upload, checkout]);

    return toExternalProject(project);
  }

  async openProject(
    item: StorageProviderItem,
    overrideLock = false,
  ): Promise<CloudStorageProject> {
    const provider = ProviderStore.getProvider(item.type);
    if (!provider) {
      throw new Error('Provider not found.');
    }

    const connection: ConnectionInfo = {
      itemId      : item.id,
      driveId     : item.driveId,
      type        : provider.type,
      accessToken : provider.tokens.accessToken,
      refreshToken: provider.tokens.refreshToken,
    };

    // TODO: Fix these types
    const createRespose: CloudStorageCreateProjectResponse | boolean =
      await CloudStorageApi.openProjectFromConnection(connection);

    if (createRespose instanceof Boolean && createRespose === false) {
      throw new Error('Failed to open project.');
    }

    const projectResponse = createRespose as CloudStorageCreateProjectResponse;

    const nameParts = getNameParts(item.name);

    const project: StoreProject = {
      ...newStoreProject(),
      id         : projectResponse.id,
      name       : nameParts.name,
      displayName: nameParts.displayName,
      extension  : nameParts.extension,
      connection : {
        type          : provider.type,
        itemId        : item.id,
        driveId       : item.driveId,
        autoSaveStatus: projectResponse.autoSaveStatus,
        cloudStatus   : projectResponse.cloudStatus,
      },
    };

    ProjectStore.setProject(project.id, project);

    await CloudStorageApi.checkOutProject(project.id, overrideLock);

    return toExternalProject(project);
  }

  async createProjectConnection(projectId: string): Promise<boolean> {
    const provider = getDefaultSaveProvider();
    if (!provider) {
      return false;
    }

    const project = ProjectStore.getProject(projectId);
    if (!project) {
      return false;
    }

    await this.verifyStorageSpace(provider.type);

    const item = await ProviderClient.createItem(provider.type, project.name);

    const connection: ConnectionInfo = {
      itemId      : item.id,
      driveId     : item.driveId,
      type        : provider.type,
      accessToken : provider.tokens.accessToken,
      refreshToken: provider.tokens.refreshToken,
    };

    await CloudStorageApi.setProjectConnection(projectId, connection);

    const nameParts = getNameParts(item.name);

    const updatedProject: StoreProject = {
      ...project,
      name       : nameParts.name,
      displayName: nameParts.displayName,
      connection : {
        ...project.connection,
        type: provider.type,
      },
    };

    ProjectStore.setProject(projectId, updatedProject);

    return true;
  }

  closeProject(projectId: string): void {
    ProjectStore.removeProject(projectId);
  }

  async syncProjectInfo(projectId: string): Promise<boolean> {
    try {
      const project = ProjectStore.getProject(projectId);
      if (!project) {
        return false;
      }

      const info = await CloudStorageApi.getProjectInfo(projectId);
      if (!info) {
        return false;
      }

      const updatedProject: StoreProject = {
        ...project,
        connection: {
          ...project.connection,
          autoSaveStatus: info.autoSaveStatus,
          cloudStatus   : info.cloudStatus,
        },
      };

      ProjectStore.setProject(projectId, updatedProject);

      return true;
    } catch {
      return false;
    }
  }

  async renameProject(projectId: string, name: string): Promise<boolean> {
    const project = ProjectStore.getProject(projectId);
    if (!project) {
      return false;
    }

    const originalNameParts = getNameParts(project.name);

    const newNameParts = getNameParts(name);
    const updatedProject: StoreProject = {
      ...project,
      ...newNameParts,
    };

    ProjectStore.setProject(projectId, updatedProject);

    if (project.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
      return true;
    }

    const newName = await ProviderClient.renameItem(project.connection, name);
    const success = Boolean(newName);

    const updatedNameParts = success ? getNameParts(newName) : originalNameParts;

    ProjectStore.setProject(projectId, {
      ...project,
      ...updatedNameParts,
    });

    return success;
  }
}

const projectService = new ProjectService();

export default projectService;
