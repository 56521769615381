import type { AsyncUserStateThunkState } from '../types';
import { AuthenticationClient } from '../../../../../clients';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';

export const fetchUserClaims = createAsyncThunk.withTypes<AsyncUserStateThunkState>()(
  'user/fetchUserClaims',
  async () => {
    const fetchedClaims = await AuthenticationClient.fetchClaims();
    if (!fetchedClaims) {
      throw new Error('No claims returned');
    }

    return {
      id                 : fetchedClaims.id,
      name               : fetchedClaims.name,
      firstname          : fetchedClaims.givenName,
      lastname           : fetchedClaims.surname,
      email              : fetchedClaims.emailAddress,
      features           : fetchedClaims.features,
      roles              : fetchedClaims.roles,
      issuingauthority   : fetchedClaims.issuingAuthority,
      status             : fetchedClaims.status,
      identifyingparty   : fetchedClaims.identifyingParty,
      subscriptionid     : fetchedClaims.subscriptionId,
      productid          : fetchedClaims.productId,
      seatid             : fetchedClaims.seatId,
      subscriptionname   : fetchedClaims.subscriptionName,
      subscriptionenddate: fetchedClaims.subscriptionEndDate,
      subscriptionmodel  : fetchedClaims.subscriptionModel,
      issubscriptionla   : fetchedClaims.isSubscriptionLA,
      deployUrl          : fetchedClaims.deployUrl,
      appSettings        : fetchedClaims.appSettings,
      valid              : true,
    };
  },
);
