export const DEFAULT_SNACKBAR_ICONS: Record<string, string> = {
  success: 'SuccessOutlinedIcon',
  warning: 'WarningOutlinedIcon',
  error  : 'ErrorOutlinedIcon',
  info   : 'InfoOutlinedIcon',
};

export const DEFAULT_SNACKBAR_PROPS = {
  severity        : 'info',
  icon            : DEFAULT_SNACKBAR_ICONS.info,
  autoHideDuration: 5000,
  anchorOrigin    : {
    horizontal: 'right',
    vertical  : 'top',
  },
};
