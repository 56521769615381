import { storage } from '@mtb/ui';
import { deepIsEqual } from '@mtb/utilities';
import {
  checkInProject,
  checkOutProject,
  createProject,
  flushProject,
  getProjectDownloadUrl,
  getProjectInfo,
  openPassthroughItemFromConnection,
  openPassthroughItemFromFile,
  openProjectFromConnection,
  setProjectAutoSave,
  setProjectConnection,
  uploadProjectContent,
} from '../api';
import {
  AUTO_SAVE_STATUS,
  BREADCRUMBS_LOADING_KEY,
  CLOUD_STATUS,
  CLOUD_STORAGE_KEY,
  CLOUD_STORAGE_PROJECTS_KEY,
  MINIMUM_STORAGE_SPACE_THRESHOLD,
  PROJECT_STATUS,
  STORAGE_PROVIDER_KEYS,
} from '../constants';
import { setOnRefreshTokenError } from '../providers/ProviderBase/ProviderApi';
import {
  canPlatformOpenInDesktop,
  downloadFileUrl,
  extractConnectionProps,
  extractProjectProps,
  getFolderName,
  getIsItemRoot,
  getNameParts,
  getProjectDesktopFileScheme,
  getProviderServiceByType,
} from '../utils';
import BroadcastServiceClient from './BroadcastServiceClient';
import configStore from './config';
import {
  createAgnosticAlertDialog,
  createAgnosticConfirmDialog,
  createAgnosticConnectionDialog,
  createAgnosticOpenInDesktopDialog,
  createAgnosticSaveToDialog,
  createAgnosticGooglePickerDialog,
  updateLoginCounter,
  createAgnosticReopenProjectFailedDialog,
} from './store-utils';

/**
 * @typedef {() => void} OnStoreChange
 */

/**
 * @typedef {() => void} UnsubscribeFn
 */

class CloudStorageStore {
  /** @type {Set<OnStoreChange>} */
  #listeners = new Set();

  #onStorageEvent() {
    this.#notifyListeners();
  }

  #notifyListeners() {
    this.#listeners.forEach((listener) => listener());
  }

  /**
   * @param {OnStoreChange} listener
   * @returns {UnsubscribeFn}
   */
  subscribe(listener) {
    this.#listeners.add(listener);
    return () => {
      this.#listeners.delete(listener);
    };
  }

  get _snapshot() {
    return {
      // Provider Info
      type                    : this.provider?.type,
      account                 : this.provider?.account,
      // Provider Actions
      changeProvider          : this.changeProvider.bind(this),
      removeProvider          : this.removeProvider.bind(this),
      addProvider             : this.addProvider.bind(this),
      // Auto-Save
      autoSaveFolder          : this.autoSaveFolder,
      setAutoSaveFolder       : this.setAutoSaveFolder.bind(this),
      verifyAutoSaveFolder    : this.verifyAutoSaveFolder.bind(this),
      getAutoSaveFolder       : this.getAutoSaveFolder.bind(this),
      // Item Retrieval
      getItemById             : this.getItemById.bind(this),
      getChildren             : this.getChildren.bind(this),
      getRecent               : this.getRecent.bind(this),
      getShared               : this.getShared.bind(this),
      getCheckoutUser         : this.getCheckoutUser.bind(this),
      getBreadcrumbs          : this.getBreadcrumbs.bind(this),
      // Item Actions
      checkInItem             : this.checkInItem.bind(this),
      deleteItem              : this.deleteItem.bind(this),
      searchItem              : this.searchItem.bind(this),
      renameItem              : this.renameItem.bind(this),
      duplicateItem           : this.duplicateItem.bind(this),
      moveItem                : this.moveItem.bind(this),
      createItem              : this.createItem.bind(this),
      verifyBeforeOpen        : this.verifyBeforeOpen.bind(this),
      // Projects
      projects                : this.projects,
      getProjectById          : this.getProjectById.bind(this),
      getProjectBreadcrumbs   : this.getProjectBreadcrumbs.bind(this),
      renameProject           : this.renameProject.bind(this),
      checkInProject          : this.checkInProject.bind(this),
      checkOutProject         : this.checkOutProject.bind(this),
      createProject           : this.createProject.bind(this),
      createPassthroughItem   : this.createPassthroughItem.bind(this),
      openProject             : this.openProject.bind(this),
      recoverProject          : this.recoverProject.bind(this),
      openPassthroughItem     : this.openPassthroughItem.bind(this),
      flushProject            : this.flushProject.bind(this),
      closeProject            : this.closeProject.bind(this),
      moveProject             : this.moveProject.bind(this),
      shareProject            : this.shareProject.bind(this),
      openProjectInDesktop    : this.openProjectInDesktop.bind(this),
      enableAutoSave          : this.enableAutoSave.bind(this),
      disableAutoSave         : this.disableAutoSave.bind(this),
      toggleAutoSave          : this.toggleAutoSave.bind(this),
      createProjectConnection : this.createProjectConnection.bind(this),
      verifyProjectConnection : this.verifyProjectConnection.bind(this),
      downloadProject         : this.downloadProject.bind(this),
      syncProjectInfo         : this.syncProjectInfo.bind(this),
      setProjectOperation     : this.setProjectOperation.bind(this),
      getIsProjectRecoverable : this.getIsProjectRecoverable.bind(this),
      healthCheckProject      : this.healthCheckProject.bind(this),
      // Cloud Storage
      isLoading               : this.isLoading,
      headlessAuth            : this.headlessAuth.bind(this),
      verifyStorageSpace      : this.verifyStorageSpace.bind(this),
      saveTo                  : this.dialogs.saveTo,
      confirmOpenNewTab       : this.dialogs.confirmOpenNewTab,
      confirmOverrideLock     : this.dialogs.confirmOverrideLock,
      alertOutOfStorage       : this.dialogs.alertOutOfStorage,
      alertDisconnectedProject: this.dialogs.alertDisconnectedProject,
      alertReconnectedProject : this.dialogs.alertReconnectedProject,
      alertLostLock           : this.dialogs.alertLostLock,
      openPicker              : this.dialogs.googlePickerDialog,
      reopenProjectFailed     : this.dialogs.reopenProjectFailed,
      supportsPicker          : this.supportsPicker.bind(this),
    };
  }
  /** @type {CloudStorageStore['_snapshot']} */
  _previousSnapshot = {};
  /**
   * This is a getter to retrieve all the commonly-used state/functions
   * for use in a useSyncExternalStore hook.
   */
  getSnapshot() {
    const newSnapshot = this._snapshot;
    let hasChanged = false;
    for (const key in newSnapshot) {
      if (
        typeof newSnapshot[key] !== 'function' &&
        !deepIsEqual(this._previousSnapshot[key], newSnapshot[key])
      ) {
        hasChanged = true;
      }
    }
    if (hasChanged) {
      this._previousSnapshot = newSnapshot;
    }
    return this._previousSnapshot;
  }

  constructor() {
    /**
     * Set the onRefreshTokenError handler to remove the provider if at any point
     * the storage service auth token is invalid.
     */
    setOnRefreshTokenError(this.removeProvider.bind(this));

    if (process.env.NODE_ENV === 'test') {
      this.__notifyListeners = () => this.#notifyListeners();
    }

    this.dialogs = {
      saveTo           : createAgnosticSaveToDialog(),
      confirmOpenNewTab: createAgnosticConfirmDialog(
        'connection.openTab',
        'connection.openTabMessage',
      ),
      confirmOverrideLock: createAgnosticConfirmDialog(
        'connection.projectLockedTitle',
        'connection.projectLockOverride',
      ),
      alertOutOfStorage: createAgnosticAlertDialog(
        'connection.outOfStorage',
        'connection.outOfStorageMessage',
      ),
      alertProjectAlreadyOpen: createAgnosticAlertDialog(
        'connection.fileCannotBeOpened',
        'connection.fileAlreadyOpenMessage',
      ),
      alertDisconnectedProject: createAgnosticConnectionDialog(
        PROJECT_STATUS.DISCONNECTED,
      ),
      alertReconnectedProject: createAgnosticConnectionDialog(
        PROJECT_STATUS.CONNECTED,
      ),
      openInDesktop: createAgnosticOpenInDesktopDialog(),
      alertLostLock: createAgnosticAlertDialog(
        'connection.projectLostLock',
        'connection.projectLostLockMessage',
      ),
      googlePickerDialog : createAgnosticGooglePickerDialog(),
      reopenProjectFailed: createAgnosticReopenProjectFailedDialog(),
    };
    window.addEventListener('storage', (e) => this.#onStorageEvent(e));

    this.addOpenedStorageItems(
      Object.values(this.projects).map((project) => project?.itemId),
    );

    if (process.env.NODE_ENV === 'test') {
      this.initialize();
    }
  }

  initialize(config) {
    configStore.updateConfig(config);

    if (this.service) {
      this.setAutoSaveFolder();
      BroadcastServiceClient.initialize();
    }

    // Update any project connection states on load so they accurately reflect
    // their connection status against the current provider.
    this.#updateProjectConnectionStates({ newType: this.service?.type });

    this.#notifyListeners();
  }

  /** @type {null | { type: import('@').StorageProviderKey, account: unknown  }} */
  #provider = null;
  get provider() {
    const providerKey = storage.localStorage.getItem(CLOUD_STORAGE_KEY);
    if (providerKey) {
      const cached = storage.localStorage.getItem(providerKey);
      if (cached?.current) {
        this.#provider = cached[cached.current];
      }
    }
    return this.#provider;
  }
  set provider(value) {
    this.#provider = value ? { ...value, ...this.#provider } : value;
    const providerKey = storage.localStorage.getItem(CLOUD_STORAGE_KEY);
    if (providerKey) {
      const cached = storage.localStorage.getItem(providerKey);
      if (cached?.current) {
        cached[cached.current] = {
          ...cached[cached.current],
          ...this.#provider,
        };
        storage.localStorage.setItem(providerKey, cached);
      }
    }

    // Ensure anything dependent on the provider's service is updated.
    if (this.service) {
      this.setAutoSaveFolder();
    }
  }
  /** @type {string | null} */
  #autoSaveFolder = null;
  get autoSaveFolder() {
    return this.#autoSaveFolder;
  }
  set autoSaveFolder(value) {
    this.#autoSaveFolder = value;
    this.#notifyListeners();
  }
  get service() {
    return getProviderServiceByType(this.provider?.type);
  }

  /** @type {{ [ProjectId: import('@').CloudStorageProjectId]: import('@').CloudStorageProject }} */
  #projects = {};
  get projects() {
    try {
      const projects = storage.sessionStorage.getItem(
        CLOUD_STORAGE_PROJECTS_KEY,
      );
      if (projects) {
        this.#projects = projects;
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error parsing cached projects:', error);
      }
      storage.sessionStorage.removeItem(CLOUD_STORAGE_PROJECTS_KEY);
    }
    return this.#projects;
  }
  set projects(value) {
    this.#projects = value ? { ...this.#projects, ...value } : value;
    storage.sessionStorage.setItem(CLOUD_STORAGE_PROJECTS_KEY, this.#projects);
  }

  /** @type {Array.<string>} */
  #openedStorageItems = [];
  get openedStorageItems() {
    return this.#openedStorageItems;
  }
  set openedStorageItems(value) {
    this.#openedStorageItems = value.filter((x) => !!x);
  }

  addOpenedStorageItems(itemIds = []) {
    this.openedStorageItems = Array.from(
      new Set([...this.openedStorageItems, ...itemIds]),
    );
  }

  removeOpenedStorageItems(itemIds = []) {
    this.#openedStorageItems = this.#openedStorageItems.filter(
      (item) => !itemIds.includes(item),
    );
  }

  #isLoading = false;
  get isLoading() {
    return this.#isLoading;
  }
  set isLoading(value) {
    this.#isLoading = value;
    this.#notifyListeners();
  }

  /** *************************************************************************
   * Storage Service Methods
   ************************************************************************* */

  /**
   * Adds a provider to the cloud storage service.
   * @param {import('@').StorageProviderKey} type - Type of the storage service.
   * @param {Object} state - State to pass to the storage service API.
   * @returns {Promise<void>}
   */
  async addProvider(type, state) {
    const service = getProviderServiceByType(type);
    await this.#updateProjectConnectionStates({ newType: type });
    await service?.login?.(state);
  }

  /**
   * Removes the current provider and clears the cache.
   * @returns {Promise<void>}
   */
  async removeProvider() {
    await this.#updateProjectConnectionStates({ newType: undefined });
    await this.service?.logout?.();
    this.provider = null;
    this.#notifyListeners();
  }

  /**
   * Change the current provider type.
   * @param {import('@').StorageProviderKey} type
   * @returns {Promise<{ service: ReturnType<typeof getProviderServiceByType>, account: Object }|false>} - If the provider type changed, returns provider information. Otherwise, returns false.
   */
  async changeProvider(type) {
    if (type !== this.provider?.type || !this.provider?.account) {
      // Immediately set the provider to null to prevent calls
      // to the storage service API with the wrong provider.
      this.provider = null;

      const service = getProviderServiceByType(type);
      const account = service?.api?.getCachedAccount?.();

      if (!account) {
        return await this.addProvider(type);
      }

      await this.#updateProjectConnectionStates({ newType: type });
      service?.api?.cacheActiveAccount?.(account?.id);
      this.provider = { type, account };
      return { service, account };
    }
    return false;
  }

  /**
   * Sets the auto-save folder for the current storage service.
   * @param {import('@').StorageProviderItem} autoSaveFolder - The auto-save folder to set.
   * @returns {void}
   */
  setAutoSaveFolder(autoSaveFolder) {
    const folder = autoSaveFolder || this.service?.api?.getAutoSaveFolder?.();
    this.service?.api?.setAutoSaveFolder?.(folder);
    this.autoSaveFolder = folder;
  }

  /**
   * Verifies the auto-save folder and updates it if necessary.
   * @returns {Promise<void>}
   */
  async verifyAutoSaveFolder() {
    if (
      this.autoSaveFolder &&
      this.autoSaveFolder.type === this.provider?.type &&
      this.autoSaveFolder.id !== 'root'
    ) {
      const folder = await this.getItemById(
        this.autoSaveFolder.id,
        this.autoSaveFolder.driveId,
      );
      if (folder.error) {
        this.autoSaveFolder = {
          ...this.getAutoSaveFolder(),
          type: this.service?.api?._providerType,
        };
      }
    }
  }

  /**
   * @returns {import('@').StorageProviderItem|null}
   */
  getAutoSaveFolder() {
    return this.service?.api?.getAutoSaveFolder?.(true);
  }

  /**
   * Verifies that there is enough storage space remaining for the current provider.
   * @returns {Promise<boolean>}
   */
  async verifyStorageSpace() {
    const remainingStorageSpace =
      await this.service?.api?.getRemainingStorageSpace();
    if (remainingStorageSpace < MINIMUM_STORAGE_SPACE_THRESHOLD) {
      await this.dialogs.alertOutOfStorage();
      return false;
    }
    return true;
  }

  /**
   * Gets an item from the provider service API.
   * @param {import('@').StorageProviderItemId} id - The ID of the item to retrieve.
   * @param {import('@').StorageProviderDriveId} [driveId] - The ID of the drive the item belongs to.
   * @param {import('@').GetItemByIdOptions} [options] - Additional options for the API call.
   * @returns {Promise<import('@').StorageProviderItem|null>} - The item retrieved by ID.
   */
  getItemById(id, driveId, { cache = true, params } = {}) {
    if (!id) {
      return Promise.resolve(null);
    }
    return this.service?.api?.getItemById?.(id, driveId, cache, params);
  }

  /**
   * Gets the children of a folder from the storage service API.
   * @param {import('@').StorageProviderItem|import('@').StorageProviderItemId} folder - The folder to get the children for.
   * @param {AbortSignal} [signal] - A signal used to cancel the request
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  getChildren(folder, signal) {
    // Handle case where a folder isn't provided (i.e. asking for root) but we do
    // have an AbortSignal
    if (folder instanceof AbortSignal) {
      signal = folder;
      folder = undefined;
    }
    return getIsItemRoot(folder)
      ? this.service?.api?.getRootChildren?.(signal)
      : this.service?.api?.getFolderChildren?.(
        folder?.id,
        folder?.driveId,
        signal,
      );
  }

  /**
   * Gets the recent items from the storage service API.
   * @param {string[]} filter - The filter parameters.
   * @param {AbortSignal} [signal] - A signal used to cancel the request
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  getRecent(filter, signal) {
    // Handle case where a filter isn't provided but we do have an AbortSignal
    if (filter instanceof AbortSignal) {
      signal = filter;
      filter = undefined;
    }
    return this.service?.api?.getRecent?.(filter, signal);
  }

  /**
   * Gets the shared items from the storage service API.
   * @param {AbortSignal} [signal] - A signal used to cancel the request
   * @returns {Promise<import('@').StorageProviderItem[] | null>}
   */
  getShared(folder, signal) {
    // Handle case where a folder isn't provided but we do have an AbortSignal
    if (folder instanceof AbortSignal) {
      signal = folder;
      folder = undefined;
      return this.service?.api?.getShared?.(signal);
    }
    return this.getChildren(folder, signal);
  }

  /**
   * Gets the checkout user for a specific item.
   * @param {import('@').StorageProviderItem} item - The item to get the checkout user for.
   * @returns {Promise<import('@').StorageProviderCheckoutUser | null>}
   */
  getCheckoutUser(item) {
    return this.service?.api?.getCheckoutUser?.(item);
  }

  /**
   * Gets the breadcrumbs for an item using the storage service API.
   * If no item is provided, the root breadcrumb will be returned.
   *
   * @param {import('@').StorageProviderItem} item - The item to get the breadcrumbs for.
   * @returns {Promise<import('@').StorageProviderItem[]>}
   */
  async getBreadcrumbs(item) {
    /** @param {import('@').StorageProviderItem[] | undefined} breadcrumbs */
    const formatBreadcrumbs = (breadcrumbs) =>
      breadcrumbs?.map((breadcrumb) => ({
        ...breadcrumb,
        name: getFolderName(this.provider?.type, breadcrumb),
      }));

    // Always get the root (no api call actually happens) so we at least have the root breadcrumb to return.
    const rootBreadcrumb = await this.service?.api?.getFolderBreadcrumbs(
      'root',
    );

    if (getIsItemRoot(item)) {
      return formatBreadcrumbs(rootBreadcrumb);
    }

    const itemBreadcrumbs = Boolean(item?.folder)
      ? await this.service?.api?.getFolderBreadcrumbs(item?.id, item?.driveId)
      : await this.service?.api?.getBreadcrumbs(item?.id, item?.driveId);
    const breadcrumbs = itemBreadcrumbs?.length
      ? itemBreadcrumbs
      : rootBreadcrumb;
    return formatBreadcrumbs(breadcrumbs);
  }

  /**
   * Checks in an item
   * @param {import('@').StorageProviderItem} item - The item to check.
   * @returns {Promise<boolean>} The checked-out state if the item is checked out, false otherwise.
   */
  async checkInItem(item) {
    if (!item.checkedOut) {
      return false;
    }
    try {
      await this.service?.api?.checkInItem(item);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Deletes an item using the storage service API.
   * @param {import('@').StorageProviderItem} item - The item to delete.
   * @returns {Promise<void>}
   */
  deleteItem(item) {
    return this.service?.api?.deleteItem?.(item);
  }

  /**
   * Searches for an item using the storage service API.
   * @param {string} query - The query to search for.
   * @param {number} maxResults - The maximum number of results to return.
   * @param {Object} options - Additional options to pass to the API.
   * @returns {Promise<import('@').StorageProviderItem[]|null>}
   */
  searchItem(query, maxResults, options) {
    return this.service?.api?.searchItem?.(query, maxResults, options);
  }

  /**
   * Renames an item using the storage service API.
   * @param {import('@').StorageProviderItem} item - The item to rename.
   * @param {import('@').StorageProviderItem['name']} name - The new name for the item.
   * @returns {Promise<string>} - The providers file name, may have been changed to prevent duplicates.
   */
  renameItem(item, name) {
    return this.service?.api?.renameItem?.(item?.id, item?.driveId, name);
  }

  /**
   * Duplicates an item using the storage service API.
   * @param {import('@').StorageProviderItem} item - The item to duplicate.
   * @returns {Promise<void>}
   */
  duplicateItem(item) {
    return this.service?.api?.duplicateItem?.(item);
  }

  /**
   * Moves an item to a folder using the storage service API.
   * @param {import('@').StorageProviderItem} item - The item to move.
   * @param {import('@').StorageProviderItem} folder - The folder to move the item to.
   * @returns {Promise<boolean>}
   */
  moveItem(item, folder) {
    try {
      if (item?.id && folder?.id) {
        return this.service.api.moveItem(item?.id, item?.driveId, folder?.id);
      }
      return false;
    } catch {
      return false;
    }
  }

  /**
   * Creates an item in the cloud storage provider's repository.
   * @param {Object} item - The item information used to create the connection.
   * @returns {Promise<import('@').StorageProviderItemConnection | false>} The connection information for the uploaded item.
   */
  createItem(item) {
    if (this.provider?.type) {
      return this.service?.api?.createItemForConnection?.(item?.name);
    }
    return Promise.resolve(false);
  }

  /** *************************************************************************
   * Storage Projects Methods
   ************************************************************************* */

  /**
   * Updates a project in the session storage.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be updated.
   * @param {import('@').CloudStorageProject} project - The updated project.
   * @returns {() => void} - A function to revert the project to its previous state.
   */
  #updateProject(projectId, project) {
    const prevProjectState = this.projects?.[projectId];

    const updatedProject = {
      ...prevProjectState,
      ...project,
    };

    this.projects = {
      ...this.projects,
      [projectId]: {
        ...updatedProject,
      },
    };

    this.addOpenedStorageItems([updatedProject?.itemId]);

    return () => this.#updateProject(projectId, prevProjectState);
  }

  /**
   * Initializes a project in the store.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be updated.
   * @param {import('@').CloudStorageProject} project - The project properties used to initialize the project.
   */
  #initializeProject(projectId, projectProps = {}) {
    const project = {
      ...projectProps,
      breadcrumbs: projectProps?.breadcrumbs || [],
    };

    this.#updateProject(projectId, project);

    this.addOpenedStorageItems([project?.itemId]);
    BroadcastServiceClient.initializeProject(project?.itemId);
  }

  /**
   * Updates the properties of all projects in the session storage.
   * @param {import('@').StorageProviderKey} newType - The type of the storage provider to check the status for.
   * @returns {Promise<void>}
   */
  async #updateProjectConnectionStates({ newType }) {
    await Promise.all(
      Object.entries(this.projects).map(async ([projectId, project]) => {
        // No need to update the project if a local project.
        if (!project?.type) {
          return;
        }

        // If the project type is different than the new type, turn off auto-save for the project.
        const shouldStopSaving = project?.type !== newType;
        if (shouldStopSaving) {
          await setProjectAutoSave(
            projectId,
            AUTO_SAVE_STATUS.TERMINATE_ON_NEXT_SAVE,
          );
          this.#updateProject(projectId, {
            autoSaveStatus: AUTO_SAVE_STATUS.NONE,
          });
        }
      }),
    );
  }

  /**
   * Gets a project from the session storage and if it doesn't exist or the cache option is false, gets it from the server.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to retrieve.
   * @returns {import('@').CloudStorageProject|undefined} The project.
   */
  getProjectById(projectId) {
    return this.projects?.[projectId];
  }

  /**
   * Gets the breadcrumbs for a project.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to get the breadcrumbs for.
   * @param {Object} options - Additional options for the API call.
   * @param {boolean} options.silent - Whether or not to put the breadcrumbs into a loading state while they are being retrieved.
   * @returns {Promise<import('@').StorageProviderItem[]>} The breadcrumbs.
   */
  async getProjectBreadcrumbs(projectId, { silent = false } = {}) {
    let undoUpdate;
    if (!silent) {
      // Optimistically set breadcrumbs to null to signify
      // that the breadcrumbs are being retrieved.
      undoUpdate = this.#updateProject(projectId, {
        breadcrumbs: BREADCRUMBS_LOADING_KEY,
      });
    }

    try {
      const project = this.getProjectById(projectId);
      if (project?.itemId && project.type === this.provider?.type) {
        const item = extractConnectionProps(project, { idKey: 'id' });
        const breadcrumbs = await this.getBreadcrumbs(
          item.id ? item : undefined,
        );
        this.#updateProject(projectId, {
          breadcrumbs,
        });
        return breadcrumbs;
      }
      // If the project is not connected but we have a cloud storage provider,
      // the breadcrumbs should display the auto-save folder location since
      // on project creation that's where the project will be saved.
      const breadcrumbs = await this.getBreadcrumbs(this.autoSaveFolder.id);
      this.#updateProject(projectId, {
        breadcrumbs,
      });
      return breadcrumbs;
    } catch {
      undoUpdate?.();
      return [];
    }
  }

  /**
   * Renames a project and updates the session storage.
   * @param {string} projectId - The ID of the project to be renamed.
   * @param {string} newName - The new name for the project.
   * @returns {Promise<boolean>}
   */
  async renameProject(projectId, newName) {
    const project = this.getProjectById(projectId);
    if (!newName || newName === project?.name) {
      return false;
    }

    const undoUpdate = this.#updateProject(
      projectId,
      extractProjectProps({ ...project, name: newName }),
    );

    // disconnected project, don't need to call the provider to rename
    if (!project?.itemId) {
      return true;
    }

    // name was optimistically updated, now do the actual work
    try {
      const item = extractConnectionProps(project, { idKey: 'id' });
      const updatedName = await this.renameItem(item, newName);

      if (!updatedName) {
        throw new Error('Did not recieved new item name back from provider');
      }

      if (updatedName !== newName) {
        // If the file name is different than the item name, update the file name
        this.#updateProject(
          projectId,
          extractProjectProps({ ...project, name: updatedName }),
        );
      }

      return true;
    } catch {
      undoUpdate();
      return false;
    }
  }

  /**
   * Creates a connection in the storage provider from the given project and updates the project in session storage.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be moved.
   * @returns {Promise<import('@').ExtractConnectionProps>} - The connection created in the storage provider.
   */
  async createProjectConnection(projectId) {
    const hasSpace = await this.verifyStorageSpace();
    if (!hasSpace) {
      return false;
    }

    if (!this.provider?.type) {
      return false;
    }

    try {
      const project = this.getProjectById(projectId);
      const item = await this.createItem(project);
      const connectionWithTokens = extractConnectionProps(item, {
        type         : this.provider?.type,
        includeTokens: true,
      });
      await setProjectConnection(projectId, connectionWithTokens);
      // If the file name is different than the item name, update the file name
      // to match the item name, since the storage provider may have changed it to prevent duplicates.
      const projectProps = extractProjectProps(
        {
          ...project,
          name: item?.name !== project?.name ? item?.name : project?.name,
        },
        { connection: connectionWithTokens },
      );
      this.#initializeProject(projectId, projectProps);
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Creates a new project from the given file.
   * @param {File} file - The file to create the project from.
   * @returns {Promise<import('@').CloudStorageProjectItem|false>} The opened project.
   */
  async createProject(file) {
    const hasSpace = await this.verifyStorageSpace();
    if (!hasSpace) {
      return false;
    }

    const { name, extension } = getNameParts(file?.name);
    if (!name) {
      throw new Error('Project name is required.');
    }

    if (!extension) {
      throw new Error('Project name must include the file extension.');
    }

    let connection;
    let projectFile = file;
    // If the user is signed in, create an item in the provider's repository
    // that represents the save location for CloudStorage service
    if (this.provider?.type) {
      const item = await this.createItem(file);
      // If the file name is different than the item name, update the file name
      // to match the item name, since the storage provider may have changed it to prevent duplicates.
      if (item?.name !== file?.name) {
        projectFile = new File([file], item.name);
      }
      connection = extractConnectionProps(item, {
        type         : this.provider?.type,
        includeTokens: true,
      });
    }

    const project = await createProject(connection);
    if (!project) {
      return false;
    }

    // Extract the project properties from the project file and the connection.
    const projectProps = extractProjectProps(projectFile, {
      autoSaveStatus: project.autoSaveStatus,
      cloudStatus   : project.cloudStatus,
      type          : this.provider?.type,
      connection,
    });

    this.#initializeProject(project.id, projectProps);

    // If the project needs to be uploaded, we can do that while we check ownership, if needed
    let upload;

    if (projectFile.size > 0) {
      upload = uploadProjectContent(project.id, projectFile);
    }

    if (connection) {
      await this.checkOutProject(project.id, false);
    }

    await upload;

    return {
      projectId  : project.id,
      name       : projectProps.name,
      displayName: projectProps.displayName,
      extension  : projectProps.extension,
    };
  }

  /**
   * Creates a new passthrough item from the given file.
   * @param {File} file - The file to create the passthrough item from.
   * @returns {Promise<import('@').CloudStoragePassthroughItem|false>} The passthrough item.
   */
  async createPassthroughItem(file) {
    const { name, extension, displayName } = getNameParts(file?.name);
    if (!name) {
      throw new Error('Item name is required.');
    }

    if (!extension) {
      throw new Error('Item name must include the file extension.');
    }

    const projectId = await openPassthroughItemFromFile(file);
    if (projectId) {
      return { projectId, name, displayName, extension };
    }

    return false;
  }

  /**
   * Opens a project from an existing storage item connection.
   * @param {import('@').StorageProviderItem} item - The storage item to open the project from.
   * @param {boolean} [overrideLock=false] - Flag to enable the user the ability to override the lock if already owned.
   * @returns {Promise<import('@').CloudStorageProjectItem|false>} The opened project.
   */
  async openProject(item, overrideLock = false) {
    const cachedTokens = this.service?.api?.getCachedAuthTokens();
    const connection = extractConnectionProps(item, {
      type         : this.provider?.type,
      includeTokens: true,
    });

    const project = await openProjectFromConnection({
      ...connection,
      accessToken : connection?.accessToken || cachedTokens?.access_token,
      refreshToken: connection?.refreshToken || cachedTokens?.refresh_token,
    });

    if (!project) {
      return false;
    }

    const projectProps = extractProjectProps(item, {
      autoSaveStatus: project.autoSaveStatus,
      cloudStatus   : project.cloudStatus,
      type          : this.provider?.type,
    });

    this.#initializeProject(project.id, projectProps);

    await this.checkOutProject(project.id, overrideLock);

    return {
      projectId  : project.id,
      name       : projectProps.name,
      displayName: projectProps.displayName,
      extension  : projectProps.extension,
    };
  }

  /**
   * Tries to recover a project using the project ID.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be downloaded.
   * @returns {Promise<import('@').CloudStorageProjectItem|false>} - Indicator of whether the project was successfully downloaded.
   */
  async recoverProject(projectId) {
    try {
      const existingProject = this.getProjectById(projectId);
      if (!existingProject) {
        throw new Error('Existing Project does not exist.');
      }

      if (!existingProject.itemId) {
        throw new Error('Cannot recover local project.');
      }

      const item = await this.getItemById(
        existingProject.itemId,
        existingProject.driveId,
      );
      if (!item) {
        throw new Error('Project item does not exist.');
      }

      this.closeProject(projectId);

      const project = await this.openProject(item, false);
      return project;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      return false;
    }
  }

  /**
   * Opens a passthrough item from an existing storage item connection.
   * @param {import('@').StorageProviderItem} item - The storage item to open the passthrough item from.
   * @returns {Promise<import('@').CloudStoragePassthroughItem|false>} The passthrough item.
   */
  async openPassthroughItem(item) {
    const cachedTokens = this.service?.api?.getCachedAuthTokens();
    const connection = extractConnectionProps(item, {
      type         : this.provider?.type,
      includeTokens: true,
    });
    const projectId = await openPassthroughItemFromConnection({
      ...connection,
      accessToken : connection?.accessToken || cachedTokens?.access_token,
      refreshToken: connection?.refreshToken || cachedTokens?.refresh_token,
    });
    if (projectId) {
      const { name, displayName, extension } = getNameParts(item.name);
      return { projectId, name, displayName, extension };
    }
    return false;
  }

  /*
   * Flushes the cloud project to the provider
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be closed.
   * @returns {Promise<boolean>}
   */
  async flushProject(projectId) {
    try {
      await flushProject(projectId);
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Closes a project and removes it from the session storage.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be closed.
   * @returns {Promise<boolean>}
   */
  async closeProject(projectId) {
    try {
      const project = this.getProjectById(projectId);
      const projectItem = extractConnectionProps(project, { idKey: 'id' });
      if (projectItem) {
        this.removeOpenedStorageItems([projectItem.id]);
        BroadcastServiceClient.closeProject(projectItem.id);
      }
      delete this.#projects[projectId];
      this.projects = { ...this.#projects };
      return true;
    } catch {
      return false;
    }
  }

  async checkInProject(projectId) {
    const project = this.getProjectById(projectId);
    if (!project) {
      return false;
    }

    const checkedIn = await checkInProject(projectId);

    this.#updateProject(projectId, {
      cloudStatus: checkedIn ? CLOUD_STATUS.READONLY : CLOUD_STATUS.OWNED,
    });

    return true;
  }

  async checkOutProject(projectId, allowOverride) {
    const project = this.getProjectById(projectId);
    if (!project) {
      return false;
    }

    let checkedOut = await checkOutProject(projectId, false);
    const isGoogle = project.type === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE;
    if (!checkedOut && isGoogle && allowOverride) {
      if (await this.dialogs.confirmOverrideLock()) {
        checkedOut = await checkOutProject(projectId, true);
      }
    }

    this.#updateProject(projectId, {
      autoSaveStatus: checkedOut
        ? AUTO_SAVE_STATUS.STARTED
        : project.autoSaveStatus,
      cloudStatus: checkedOut ? CLOUD_STATUS.OWNED : CLOUD_STATUS.READONLY,
    });

    return checkedOut;
  }

  /**
   * Moves a project to a new folder and updates the session storage.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to be moved.
   * @returns {Promise<boolean>} - Indicator of whether the move was successful
   */
  async moveProject(projectId) {
    let undoUpdate;
    try {
      const project = this.getProjectById(projectId);
      const projectBreadcrumbs = project?.breadcrumbs.length
        ? project?.breadcrumbs
        : await this.getProjectBreadcrumbs(projectId);
      const projectItem = extractConnectionProps(project, { idKey: 'id' });
      const defaultFolder = projectBreadcrumbs?.at(-1);
      const newFolder = await this.dialogs.saveTo({ defaultFolder });

      undoUpdate = this.#updateProject(projectId, {
        breadcrumbs: BREADCRUMBS_LOADING_KEY,
      });

      if (!newFolder || !projectItem) {
        undoUpdate();
        return false;
      }

      // Don't wait, because we have the folder already.
      // This improves the UX, by not waiting for the move to complete.
      this.moveItem(projectItem, newFolder);
      const newFolderBreadcrumbs = await this.getBreadcrumbs(newFolder);
      this.#updateProject(projectId, {
        breadcrumbs: newFolderBreadcrumbs,
      });
      return true;
    } catch (error) {
      undoUpdate();
      console.error(error);
      return false;
    }
  }

  /**
   * Shows the storage provider's share file dialog.
   * @param {import('@').CloudStorageProjectId} projectId
   * @returns {Promise<boolean>}
   */
  async shareProject(projectId) {
    try {
      const project = this.getProjectById(projectId);
      if (project) {
        await this.service?.showShareFileDialog(
          project?.itemId,
          project?.driveId,
        );
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }

  /**
   * Attempts to open the project in the Desktop application for the project's file extension
   * @param {import('@').CloudStorageProjectId} projectId
   * @param {() => void} onReopenProject - Callback to reopen the project
   * @returns {Promise<boolean>}
   */
  async openProjectInDesktop(projectId, onReopenProject) {
    if (!canPlatformOpenInDesktop()) {
      return false;
    }
    try {
      const project = this.getProjectById(projectId);
      if (!project) {
        return false;
      }
      await this.flushProject(projectId);
      await this.checkInProject(projectId);
      // We are not awaiting the dialog here, because the promise to the dialog doesn't resolve until
      // the user closes the dialog. If we were to do that, we'd call the open below after the user closed the dialog.
      this.dialogs.openInDesktop({
        projectId,
        onReopenProject,
      });
      const params = await this.#getOpenInDesktopParams(project);
      const fileScheme = getProjectDesktopFileScheme(project);
      downloadFileUrl(`${fileScheme}:${params}`);
      return true;
    } catch {
      return false;
    }
  }

  /**
   *
   * @param {import('@').CloudStorageProject} project
   * @returns {Promise<import('@').OpenInDesktopParams>}
   */
  async #getOpenInDesktopParams(project) {
    const user = getProviderServiceByType(project.type).api.getCachedAccount();
    let params;
    switch (project.type) {
      case STORAGE_PROVIDER_KEYS.ONE_DRIVE:
        params = {
          type     : project.type,
          id       : project.itemId,
          drive    : project.driveId,
          loginHint: user?.email,
        };
        break;
      case STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE:
        const providerItemInfo = await this.getItemById(
          project.itemId,
          project.driveId,
          { cache: false },
        );
        params = {
          name      : providerItemInfo.name,
          login_hint: user?.email,
          parentId  : providerItemInfo.parentReference.id,
          type      : project.type,
          id        : project.itemId,
        };
        break;
      default:
        break;
    }

    let paramStr = new URLSearchParams(params).toString();
    paramStr = paramStr.replace(/\+/g, '%20'); // MSSO requires the + instead
    return paramStr;
  }

  /**
   * Enables auto-save for a project.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to enable auto-save for.
   * @returns {Promise<boolean>}
   */
  async enableAutoSave(projectId) {
    const project = this.getProjectById(projectId);
    const existsInCloud = project.type && project.type === this.provider.type;
    const isOwned = project.cloudStatus === CLOUD_STATUS.OWNED;

    // If the project is not connected. create a connection to the
    // new provider before enabling auto-save.
    if (!existsInCloud) {
      const didCreate = await this.createProjectConnection(projectId);
      if (!didCreate) {
        return false;
      }

      // We don't need to wait for this to complete, because the UI will show a loading state
      // while they are being retrieved.
      this.getProjectBreadcrumbs(projectId, {
        silent: Boolean(project?.breadcrumbs),
      });
    }

    if (!existsInCloud || !isOwned) {
      return await this.checkOutProject(projectId, true);
    }

    const autoSaveEnabled = await setProjectAutoSave(
      projectId,
      AUTO_SAVE_STATUS.STARTED,
    );
    if (!autoSaveEnabled) {
      return false;
    }

    this.#updateProject(projectId, {
      autoSaveStatus: AUTO_SAVE_STATUS.STARTED,
      cloudStatus   : CLOUD_STATUS.OWNED,
      status        : PROJECT_STATUS.CONNECTED,
    });

    return true;
  }

  /**
   * Disables auto-save for a project.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to disable auto-save for.
   * @returns {Promise<boolean>}
   */
  async disableAutoSave(projectId) {
    const successfullyDisabled = await setProjectAutoSave(
      projectId,
      AUTO_SAVE_STATUS.PAUSE_ON_NEXT_SAVE,
    );
    if (!successfullyDisabled) {
      return false;
    }

    this.#updateProject(projectId, {
      autoSaveStatus: AUTO_SAVE_STATUS.NONE,
    });

    return true;
  }

  /**
   * Toggle auto-save for a project.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to disable auto-save for.
   * @returns {Promise<boolean>}
   */
  async toggleAutoSave(projectId) {
    const project = this.getProjectById(projectId);
    if (!project) {
      return false;
    }

    if (project.autoSaveStatus === AUTO_SAVE_STATUS.STARTED) {
      return await this.disableAutoSave(projectId);
    }

    return await this.enableAutoSave(projectId);
  }

  /**
   * Fetches the latest project info. If we've lost ownership let the user know
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to disable auto-save for.
   * @returns {Promise<import('@').CloudStorageProject>} - The updated project info.
   */
  async syncProjectInfo(projectId) {
    try {
      const syncedProject = await this.#syncProjectInfoInternal(projectId);

      if (syncedProject.lostLock) {
        this.dialogs.alertLostLock(); // no need to await the dialog
      }

      return syncedProject.project;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to sync project info.');
    }
  }

  /**
   * Syncs the project info with the server.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to disable auto-save for.
   * @returns {Promise<import('@').UpdatedCloudStorageProjectInformation>} - The updated project info.
   */
  async #syncProjectInfoInternal(projectId) {
    try {
      const originalProjectInfo = this.getProjectById(projectId);
      const projectInfo = await getProjectInfo(projectId);

      const autoSaveStatus =
        projectInfo.autoSaveStatus === AUTO_SAVE_STATUS.STARTED
          ? AUTO_SAVE_STATUS.STARTED
          : AUTO_SAVE_STATUS.NONE;
      this.#updateProject(projectId, {
        autoSaveStatus,
        cloudStatus: projectInfo.cloudStatus,
      });

      const updatedProjectInfo = this.getProjectById(projectId);

      const isSignedIn = Boolean(this.provider?.type);
      const projectHasConnection = Boolean(updatedProjectInfo?.type);
      const hasMatchingProvider =
        updatedProjectInfo.type === this.provider?.type;
      const projectIsAutoSaving =
        isSignedIn &&
        updatedProjectInfo.autoSaveStatus === AUTO_SAVE_STATUS.STARTED;
      const isReadonly =
        isSignedIn && updatedProjectInfo.cloudStatus === CLOUD_STATUS.READONLY;
      const lostLock =
        isSignedIn &&
        originalProjectInfo?.cloudStatus === CLOUD_STATUS.OWNED &&
        updatedProjectInfo?.cloudStatus === CLOUD_STATUS.READONLY;

      return {
        project: updatedProjectInfo,
        isSignedIn,
        projectHasConnection,
        hasMatchingProvider,
        projectIsAutoSaving,
        isReadonly,
        lostLock,
      };
    } catch (error) {
      throw new Error('Failed to sync project info.');
    }
  }

  setProjectOperation(projectId, operation) {
    const project = this.getProjectById(projectId);
    if (!project) {
      return;
    }

    this.#updateProject(projectId, {
      operation,
    });
  }

  /**
   * Checks if a project is recoverable, by checking if the project exists and has a storage item ID.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to check.
   * @returns {Promise<boolean>} - Indicator of whether the project is recoverable.
   */
  async getIsProjectRecoverable(projectId) {
    try {
      const project = this.getProjectById(projectId);
      if (!project || !project.itemId) {
        return false;
      }
      const item = await this.getItemById(project.itemId, project.driveId);
      return Boolean(item);
    } catch {
      return false;
    }
  }

  /**
   * Checks the project's connection status and notifies the user if action is needed.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to disable auto-save for.
   * @returns {Promise<void>}
   */
  async verifyProjectConnection(projectId) {
    try {
      const syncedProject = await this.#syncProjectInfoInternal(projectId);

      // Three scenarios here:
      // 1. You're not signed in and the project has a connection, prompt the user to sign in.
      if (!syncedProject.isSignedIn && syncedProject.projectHasConnection) {
        this.dialogs.alertDisconnectedProject({ projectId });
        return;
      }

      // 2. You previously had the lock, but lost it.
      if (syncedProject.lostLock) {
        this.dialogs.alertLostLock();
        return;
      }

      // 3. You're signed in, the project has a connection, and the provider types match.
      if (
        syncedProject.isSignedIn &&
        syncedProject.projectHasConnection &&
        syncedProject.hasMatchingProvider &&
        !syncedProject.isReadonly &&
        !syncedProject.projectIsAutoSaving
      ) {
        const didConnect = await this.dialogs.alertReconnectedProject({
          projectId,
        });
        if (didConnect) {
          this.enableAutoSave(projectId);
        }
      }
    } catch (error) {
      throw new Error('Failed to verify project connection.');
    }
  }

  /**
   * Fires a health check for the project to ensure it's still alive
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to disable auto-save for.
   * @returns {Promise<boolean>} - Indicator of whether the project is still alive.
   */
  async healthCheckProject(projectId) {
    try {
      const project = this.getProjectById(projectId);
      if (!project) {
        return false;
      }

      const info = await getProjectInfo(projectId);
      return Boolean(info);
    } catch {
      return false;
    }
  }

  /**
   * Downloads a project using either the project's download URL or the storage provider's API.
   * @param {import('@').CloudStorageProjectId} projectId - The ID of the project to download.
   * @returns {boolean} - Indicator of whether the project was successfully downloaded.
   */
  downloadProject(projectId) {
    const project = this.getProjectById(projectId);
    const url = getProjectDownloadUrl(projectId, project?.name);
    downloadFileUrl(url, project?.name);
    return true;
  }

  /**
   * Verifies that the item to be used to create a project is not already open.
   * @param {import('@').StorageProviderItem} item - The item to verify.
   * @returns {boolean} - Indicator of whether the item is already open.
   */
  verifyBeforeOpen(item) {
    const isAlreadyOpen = this.openedStorageItems.some(
      (checkedOutItemId) => checkedOutItemId === item?.id,
    );

    if (isAlreadyOpen) {
      this.dialogs.alertProjectAlreadyOpen();
      return false;
    }
    return true;
  }

  /** *************************************************************************
   * Cloud Storage Methods
   ************************************************************************* */
  /**
   * Authenticate with a provider in headless mode.
   * @param {import('@').StorageProviderKey} type - Type of the storage provider.
   * @param {Object} state - State parameters for authentication.
   * @returns Authentication result containing type, tokens, and user information.
   */
  async headlessAuth(type, state) {
    const service = getProviderServiceByType(type);

    if (service) {
      if (service.api.getCachedAuthTokens()) {
        // If tokens exist but the user is not authorized, a login will occur in CloudApi.js
        // in the e.needsAuthentication error catch. Temporarily store the state in session storage
        // to use on that login attempt so the userAction is preserved.
        storage.sessionStorage.setItem('user-action-state', state);
        const user = await service.api.getAccount();
        storage.sessionStorage.removeItem('user-action-state');
        return {
          type,
          tokens: service.api.getCachedAuthTokens(),
          user,
        };
      }
      // If tokens don't exist, initiate the login process
      service.login(state);
    }
  }

  /**
   * Handle the intercepted auth response and update the provider.
   * @param {import('@').InterceptedAuth} auth - Intercepted authentication response.
   * @returns {Promise<void>}
   */
  async handleInterceptedAuth(auth) {
    const type = auth?.state?.type;
    if (type === STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      updateLoginCounter();
    }

    if (!type || !Object.values(STORAGE_PROVIDER_KEYS).includes(type)) {
      this.removeProvider();
      return;
    }

    const service = getProviderServiceByType(type);
    if (service) {
      this.isLoading = true;
      const account = await service.api?.getOrConnectAccount(auth?.response);
      service.api?.cacheActiveAccount(account?.id);
      await this.changeProvider(type);
      this.isLoading = false;
    }
  }

  /**
   * Checks if file/folder picker dialog is supported.
   * @returns {boolean}
   */
  supportsPicker() {
    return !!this.service?.openPicker;
  }

  async getOneDriveItemFromShareURL(url) {
    return await this.service?.api?.getOneDriveItemFromShareURL(url);
  }
}

/**
 * @typedef {CloudStorageStore} CloudStorageStoreType
 */

export const cloudStorageStore = new CloudStorageStore();

export default cloudStorageStore;
