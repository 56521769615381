import type { UserState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { LoggerClient } from '../../../../../clients';
import { initialState } from '../initial-state';
import { logoutUser } from './thunk';

export const logoutUserReducer = (builder: ActionReducerMapBuilder<UserState>) => {
  return (
    builder
      .addCase(logoutUser.pending, state => {
        state.claims = initialState.claims;
      })
      .addCase(logoutUser.fulfilled, () => undefined)
      .addCase(logoutUser.rejected, (state, payload) => {
        LoggerClient.error('[logoutUser] Error logging out user...', payload.error);
      })
  );
};
