import type { CloudStorageProviderItem } from './types';
import type { CloudStorageProjectItem, GetItemByIdOptions, InterceptedOpenState } from '@mtb/cloud-storage/types';
import { AUTO_SAVE_STATUS, CLOUD_STATUS, CloudStorage, CloudStorageApi, STORAGE_PROVIDER_KEYS, getNameParts } from '@mtb/cloud-storage';
import config from '../../config';

/**
 * CloudStorageClient is a wrapper around the CloudStorage npm module to centralize the
 * interactions between core and the cloud storage module.
 */
class CloudStorageClient {
  /**
   * Gets the name parts from the given name.
   * @param name - The name to get the parts from.
   * @returns The name parts.
   */
  getNameParts(name: string): ReturnType<typeof getNameParts> {
    return getNameParts(name);
  }

  /**
   * Verifies the given item before opening it.
   * @param item - The item to verify before opening.
   * @returns The verification result.
   */
  verifyBeforeOpen(item: CloudStorageProviderItem): boolean {
    if (!item) {
      throw new Error('Failed to verify before open: item is required.');
    }
    return CloudStorage.verifyBeforeOpen(item);
  }

  /**
   * Gets the cloud storage project with the given ID.
   * @param id - The ID of the cloud storage project to get.
   * @param driveId - The drive ID of the cloud storage project to get.
   * @param options - The options to get the cloud storage project with.
   * @returns The cloud storage project with the given ID.
   */
  async getItemById(
    id: string,
    driveId?: string,
    options?: GetItemByIdOptions,
  ): ReturnType<typeof CloudStorage.getItemById> {
    // cloud-storage's driveId and options arguments to be optional.
    return await CloudStorage.getItemById(id, driveId, options);
  }

  /**
   * Creates a new project with the given file
   * @param file - The file to create the project from.
   * @returns
   */
  async createProject(file: File): Promise<CloudStorageProjectItem> {
    if (!file) {
      throw new Error('Failed to create project: file is required.');
    }

    if (config.feature_flag_cs_store_v2) {
      const project = await CloudStorageApi.Project.createProject(file);
      return {
        projectId  : project.id,
        name       : project.name,
        displayName: project.displayName ?? '',
        extension  : project.extension ?? '',
      };
    }

    const project = await CloudStorage.createProject(file);
    if (!project) {
      throw new Error('Failed to create project.');
    }

    return {
      projectId  : project.projectId,
      name       : project.name,
      displayName: project.displayName,
      extension  : project.extension,
    };
  }

  /**
   * Creates a project using the given cloud storage item.
   * @param item - The cloud storage item to create the project from.
   * @param overrideLock - Whether to override the lock on the item.
   * @returns The created project.
   */
  async openProject(
    item: CloudStorageProviderItem,
    overrideLock?: boolean,
  ): Promise<CloudStorageProjectItem | boolean> {
    if (!item) {
      throw new Error('Failed to open project: item is required.');
    }
    if (config.feature_flag_cs_store_v2) {
      const project = await CloudStorageApi.Project.openProject(item, overrideLock);
      return {
        projectId  : project.id,
        name       : project.name,
        displayName: project.displayName ?? '',
        extension  : project.extension ?? '',
      };
    }
    return await CloudStorage.openProject(item, overrideLock);
  }

  /**
   * Creates a passthrough item from the given file.
   * @param file - The file to create a passthrough item from.
   * @returns The created passthrough item.
   */
  async createPassthroughItem(file: File): ReturnType<typeof CloudStorage.createPassthroughItem> {
    if (!file) {
      throw new Error('Failed to create passthrough item: file is required.');
    }
    return await CloudStorage.createPassthroughItem(file);
  }

  /**
   * Opens the given passthrough item.
   * @param storageItem - The passthrough item to open.
   * @returns The opened passthrough item.
   */
  async openPassthroughItem(
    storageItem: CloudStorageProviderItem,
  ): ReturnType<typeof CloudStorage.openPassthroughItem> {
    if (!storageItem) {
      throw new Error('Failed to open passthrough item: storageItem is required.');
    }
    return await CloudStorage.openPassthroughItem(storageItem);
  }

  /**
   * Gets the OneDrive item from the given share URL.
   * @param url - The share URL to get the OneDrive item from.
   * @returns The OneDrive item from the given share URL.
   */
  async getOneDriveItemFromShareURL(url: string): ReturnType<typeof CloudStorage.getOneDriveItemFromShareURL> {
    return await CloudStorage.getOneDriveItemFromShareURL(url);
  }

  /**
   * Gets the project with the given ID.
   * @param projectId - The project ID to get.
   * @returns The project with the given ID.
   */
  getProjectById(projectId: string): ReturnType<typeof CloudStorage.getProjectById> {
    return CloudStorage.getProjectById(projectId);
  }

  /**
   * Determines whether the project with the given ID is currently unsaved.
   * @param projectId - The project ID to check.
   * @returns Whether the project is currently unsaved.
   */
  isUnsavedProject(projectId: string): boolean {
    if (!projectId) {
      return false;
    }

    const cloudStorageProject = this.getProjectById(projectId);
    const isReadOnlyProject = cloudStorageProject?.cloudStatus === CLOUD_STATUS.READONLY;
    const isProjectAutoSaving = cloudStorageProject?.autoSaveStatus === AUTO_SAVE_STATUS.STARTED;
    return !isReadOnlyProject && !isProjectAutoSaving;
  }

  /**
   * Renames the given storage item with the given name.
   * @param item - The item to rename.
   * @param name - The new name of the item.
   * @returns The renamed item.
   */
  async renameItem(item: CloudStorageProviderItem, name: string): Promise<string | void> {
    if (!item) {
      throw new Error('Failed to rename item: item is required.');
    }
    return await CloudStorage.renameItem(item, name);
  }

  /**
   * Duplicates the given item.
   * @param item - The item to duplicate.
   * @returns The duplicated item.
   */
  async duplicateItem(item: CloudStorageProviderItem): Promise<CloudStorageProviderItem | void> {
    if (!item) {
      throw new Error('Failed to duplicate item: item is required.');
    }
    return await CloudStorage.duplicateItem(item);
  }

  /**
   * Gets the storage item from cloud storage using the state object.
   * @param state - The state object to get the storage item from.
   * @returns The storage item from cloud storage.
   */
  async getStorageItemFromState(state: InterceptedOpenState): Promise<CloudStorageProviderItem> {
    if (!state) {
      throw new Error('Failed to get item from state: state is required.');
    }

    const item =
      state?.connectionType === STORAGE_PROVIDER_KEYS.ONE_DRIVE
        ? await CloudStorage.getOneDriveItemFromShareURL(state.url)
        : await CloudStorage.getItemById(state.ids?.[0] ?? state.exportIds?.[0]);
    if (!item) {
      throw new Error('Failed to get item from state: an item could not be found.');
    }
    return item;
  }

  async healthCheckProject(projectId: string): Promise<boolean> {
    try {
      const check = config.feature_flag_cs_store_v2
        ? CloudStorageApi.Project.syncProjectInfo(projectId)
        : CloudStorage.healthCheckProject(projectId);
      return await check;
    } catch {
      return false;
    }
  }

  async closeProject(projectId: string): Promise<boolean> {
    if (config.feature_flag_cs_store_v2) {
      CloudStorageApi.Project.closeProject(projectId);
      return true;
    }
    return await CloudStorage.closeProject(projectId);
  }

  async recoverProject(projectId: string): Promise<CloudStorageProjectItem | null> {
    const recovered = await CloudStorage.recoverProject(projectId);
    return recovered ? recovered : null;
  }

  async getIsProjectRecoverable(projectId: string): Promise<boolean> {
    return await CloudStorage.getIsProjectRecoverable(projectId);
  }

  async renameProject(projectId: string, name: string): Promise<boolean> {
    return await CloudStorage.renameProject(projectId, name);
  }
}

const cloudStorageClient = new CloudStorageClient();

export default cloudStorageClient;
