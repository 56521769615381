import {
  STORAGE_PROVIDER_KEYS,
  FILE_ICONS,
  DESKTOP_APP_FILE_ASSOCIATIONS,
} from './constants';
import { gDriveService, msGraphService } from './providers';
import { getI18nStore } from './services/i18n';

/**
 * Checks if the given item is a Google Sheet.
 * @param {import('@').StorageProviderItem} item
 * @returns {boolean}
 */
export const isGoogleSheet = (item) =>
  item.mimeType === 'application/vnd.google-apps.spreadsheet';

/**
 * Gets the associated icon for the given storage item.
 * @param {import('@').StorageProviderItem} item - The storage item.
 * @returns {JSX.Element | null} - The associated icon.
 */
export const getStorageItemIcon = (item) => {
  return (
    FILE_ICONS?.[item?.folder ? 'folder' : item?.extension?.toLowerCase()] ||
    null
  );
};

/**
 * Gets the associated desktop file scheme for the given project.
 * @param {import('@').CloudStorageProject} project - The cloud storage project.
 * @returns {string} - The associated desktop extension.
 */
export const getProjectDesktopFileScheme = (project) => {
  return DESKTOP_APP_FILE_ASSOCIATIONS[project?.extension?.toLowerCase()] || '';
};

/**
 * Filters items based on the provided filter array.
 * @param {import('@').StorageProviderItem[]} items - The array of storage explorer items.
 * @param {string[]} filter - The array of filters to apply.
 * @returns {import('@').StorageProviderItem[]} The filtered array of storage explorer items.
 */
export const filterItems = (items = [], filter = []) => {
  return items.filter((item) => {
    if (!item) {
      return false;
    }

    if (item.folder) {
      return true;
    }

    const fileExtension = getNameParts(item.name).extension;
    return fileExtension && filter.includes(`.${fileExtension?.toLowerCase()}`);
  });
};

/**
 * Gets the item timestamps for the given item.
 * @param {import('@').StorageProviderItem} item - The item to get the timestamps for.
 * @returns {import('@').StorageItemTimestamps} - The item timestamps.
 */
export const getItemTimestamps = (item) => {
  if (!item) {
    throw new Error('Item is required.');
  }

  const lastModifiedDateTime =
    item?.lastModifiedDateTime || item?.modifiedTime || item.modifiedDate;
  const lastAccessed = new Date(lastModifiedDateTime).toDateString();
  const lastAccessedElapsedTime = getElapsedTime({
    lastAccessed,
    lastModifiedDateTime,
  });

  return {
    lastAccessed,
    lastAccessedElapsedTime,
    lastModifiedDateTime,
  };
};

/**
 * Extracts the connection related properties from the given storage item.
 * @type {import('@').ExtractConnectionProps}
 */
export const extractConnectionProps = (item, options = {}) => {
  const { id, itemId, driveId, parentId, type, tokens, checkedOut } =
    item || {};
  return {
    // Support switching the id key because project and storage items use different keys
    // TODO: Remove this once we have are using itemId everywhere.
    [options?.idKey || 'itemId']: id || itemId,
    type                        : type || options?.type,
    driveId,
    parentId,
    checkedOut                  : checkedOut || options?.checkedOut,
    ...(options?.includeTokens ? { refreshToken: tokens?.refresh_token } : {}),
    ...(options?.includeTokens ? { accessToken: tokens?.access_token } : {}),
  };
};

/**
 * Extracts the project info from the given storage item.
 * @type {import('@').ExtractProjectProps}
 */
export const extractProjectProps = (
  item = {},
  { connection, ...restOptions } = {},
) => {
  const { name, displayName, extension } = getNameParts(item.name);
  // Extract even if connection is passed to ensure we aren't storing the connection tokens in session storage
  const projectConnection = extractConnectionProps(connection || item, {
    ...restOptions,
    includeTokens: false,
  });
  return {
    ...projectConnection,
    name          : name || item.name,
    displayName   : displayName || name,
    extension     : extension || item.extension,
    autoSaveStatus: restOptions.autoSaveStatus || item.autoSaveStatus,
    cloudStatus   : restOptions.cloudStatus || item.cloudStatus,
  };
};

/**
 * Downloads a file from the given url.
 * @param {string} url The url to download the file from.
 * @param {string} fileName The name of the file to be downloaded.
 */
export const downloadFileUrl = (url, fileName) => {
  const link = document.createElement('a');
  link.id = fileName;
  link.href = url;
  if (fileName) {
    link.setAttribute('download', fileName);
  }
  // since we're downloading in the same tab prevent navigation and therefore unwanted
  // events being fired, such as 'beforeunload'
  link.target = '_blank';
  link.click();
};

/**
 * Gets the file name without extension from the full file name. Handles files that start with "." as well.
 * @param {string} name
 */
const basename = (name) =>
  name.slice(0, ((name.lastIndexOf('.') - 1) >>> 0) + 1);

/**
 * Gets the file extension from the full file name. Handles files that start with "." as well.
 * @param {string} name
 */
const extname = (name) => name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2);

/**
 * Splits the file name into name and extension.
 * @param {string} name - The file name.
 * @returns {import('@').StorageItemNameParts} - The name parts.
 */
export const getNameParts = (name = '') => {
  const displayName = basename(name || '');
  const extension = extname(name || '');

  return {
    name       : name || '',
    displayName: displayName || '',
    extension  : extension || '',
  };
};

/**
 * Checks if the given item is the root item.
 * @param {import('@').StorageProviderItem|import('@').StorageProviderItem['id']} itemOrId - The item to check.
 * @returns {boolean} True if the item is the root item, false otherwise.
 */
export const getIsItemRoot = (itemOrId) =>
  !itemOrId ||
  typeof itemOrId === 'string' ||
  itemOrId?.id === 'root' ||
  itemOrId?.parents?.length === 0;

/**
 * Gets the root folder name for display purposes.
 * @param {import('@').StorageProviderKey} type - The type of the storage provider.
 * @returns {string} The root folder name.
 */
export const getRootFolderName = (type) => {
  return getI18nStore().t(
    type === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE
      ? 'connection.drive'
      : 'connection.files',
  );
};

/**
 * Gets the folder name for display purposes.
 * @param {import('@').StorageProviderKey} type - The type of the storage provider.
 * @param {import('@').StorageProviderItem} folder - The folder object.
 * @returns {string} The folder name.
 */
export const getFolderName = (type, folder) => {
  const isRoot = getIsItemRoot(folder);
  return isRoot ? getRootFolderName(type) : folder?.name;
};

/**
 * Asynchronously sleeps for the specified duration.
 * @param {number} seconds - The number of seconds to sleep.
 * @returns {Promise<void>} - A Promise that resolves after the specified duration.
 */
export const sleep = (seconds) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

/**
 * Calculates and returns a human-readable elapsed time string based on the provided item and translation function.
 * @param {import('@').StorageItem} item - The item with a lastModifiedDateTime property.
 * @returns {string} - Human-readable elapsed time string.
 */
export const getElapsedTime = (item) => {
  if (!item) {
    return '';
  }

  const elapsedMinutes =
    (Date.now() - Date.parse(item.lastModifiedDateTime)) / 60000;
  const t = getI18nStore().t;

  if (elapsedMinutes < 0.8) {
    return t('connection.justNow');
  } else if (elapsedMinutes >= 0.8 && elapsedMinutes < 2) {
    return t('connection.aboutAMinuteAgo');
  } else if (elapsedMinutes >= 2 && elapsedMinutes < 59) {
    return `${t('connection.minutesAgo', [Math.round(elapsedMinutes)])}`;
  } else if (elapsedMinutes >= 59 && elapsedMinutes < 110) {
    return t('connection.aboutAnHourAgo');
  } else if (elapsedMinutes >= 110 && elapsedMinutes < 1440) {
    return `${t('connection.hoursAgo', [Math.round(elapsedMinutes / 60)])}`;
  } else if (elapsedMinutes >= 1440 && elapsedMinutes < 2880) {
    return `${t('connection.yesterday')}`;
  } else if (elapsedMinutes >= 2880 && elapsedMinutes < 10000) {
    return `${t('connection.daysAgo', [Math.round(elapsedMinutes / 1440)])}`;
  }
  return item.lastAccessed;
};

/**
 * Get connection service by type.
 * @template {import('@').StorageProviderKey | ""} [Type=""]
 * @param {Type} type - The type of the storage provider.
 * @returns {import('@').ConnectionService<Type>|null} - The corresponding connection service or null if type is not recognized.
 */
export const getProviderServiceByType = (type) => {
  switch (type) {
    case STORAGE_PROVIDER_KEYS.ONE_DRIVE:
      return msGraphService;
    case STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE:
      return gDriveService;
    default:
      return null;
  }
};

/**
 * Validates that a user's device platform can handle the "Open in desktop" functionality.
 * @returns {boolean}
 */
export const canPlatformOpenInDesktop = () => {
  if (navigator?.userAgentData?.platform?.toLowerCase?.() !== 'windows') {
    return false;
  }
  if (
    !navigator?.userAgentData?.platform &&
    !['win32', 'win64', 'windows'].includes(navigator.platform?.toLowerCase?.())
  ) {
    return false;
  }
  return true;
};
