import { useCallback, useMemo } from 'react';
import { useEventCallback } from '@mtb/ui';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  STORAGE_PROVIDER_KEYS,
} from '../constants';
import ProjectService from '../services/projects';
import {
  canPlatformOpenInDesktop,
  getProjectDesktopFileScheme,
} from '../utils';
import { useNewCloudStorageStore } from './useCloudStorageStore';

/**
 * Custom hook for managing a cloud storage project.
 * @param {import('@').CloudStorageProjectId} projectId - The ID of the cloud storage project.
 */
export const useNewCloudStorageProject = (projectId) => {
  const { projects } = useNewCloudStorageStore();
  const project = useMemo(() => projects[projectId], [projects, projectId]);
  const breadcrumbs = useMemo(
    () => project?.breadcrumbs,
    [project?.breadcrumbs],
  );

  /**
   * Gets the breadcrumbs for the cloud storage project.
   */
  const handleGetBreadcrumbs = useCallback(
    async () => {}, // await cloudStorage.getProjectBreadcrumbs(projectId),
    [],
  );

  /**
   * Gets the checkoutUser for the cloud storage project.
   */
  const handleGetCheckoutUser = useCallback(async () => {
    // const item = await cloudStorage.getItemById(
    //   project?.itemId,
    //   project?.driveId,
    // );
    // const checkoutUser = await cloudStorage.getCheckoutUser(item);
    // return checkoutUser;
  }, []);

  /**
   * Handles the download of the cloud storage project.
   */
  const handleDownloadProject = useCallback(
    () => {}, // cloudStorage.downloadProject(projectId),
    [],
  );

  /**
   * Renames the cloud storage project.
   */
  const handleRenameProject = useCallback(
    async (newName) => {
      ProjectService.renameProject(projectId, newName);
    },
    [projectId],
  );

  /**
   * Moves the cloud storage project to the given folder.
   */
  const handleMoveProject = useCallback(
    /**
     * @param {import('@').StorageProviderItem} newFolder
     */
    (newFolder) => {}, // cloudStorage.moveProject(projectId, newFolder),
    [],
  );

  /**
   * Shares the cloud storage project.
   */
  const handleShareProject = useCallback(
    async () => {}, // await cloudStorage.shareProject(projectId),
    [],
  );

  /**
   * Shares the cloud storage project.
   */
  const handleOpenInDesktop = useCallback(
    async (onReopenProject) => {},
    // await cloudStorage.openProjectInDesktop(projectId, onReopenProject),
    [],
  );

  /**
   * Flushes the cloud storage project.
   */
  const handleFlushProject = useCallback(
    () => {}, // cloudStorage.flushProject(projectId),
    [],
  );

  /**
   * Closes the cloud storage project.
   */
  const handleCloseProject = useCallback(
    () => {}, // cloudStorage.closeProject(projectId),
    [],
  );

  /**
   * Enables auto-save for the cloud storage project.
   */
  const handleEnableAutoSave = useCallback(
    () => {}, // cloudStorage.enableAutoSave(projectId),
    [],
  );

  /**
   * Disables auto-save for the cloud storage project.
   */
  const handleDisableAutoSave = useCallback(
    () => {}, // cloudStorage.disableAutoSave(projectId),
    [],
  );

  /**
   * Toggles auto-save for the cloud storage project.
   */
  const handleToggleAutoSave = useCallback(
    () => {}, // cloudStorage.toggleAutoSave(projectId),
    [],
  );

  /**
   * Verifies the connection of the cloud storage project.
   */
  const handleVerifyConnection = useEventCallback(
    () => {},
    // cloudStorage.verifyProjectConnection(projectId),
  );

  /**
   * Syncs the project information with cloud storage API.
   */
  const handleSyncProject = useCallback(
    () => {}, // cloudStorage.syncProjectInfo(projectId),
    [],
  );

  /**
   * Checks in the cloud storage project.
   */
  const handleCheckIn = useCallback(
    () => {}, // cloudStorage.checkInProject(projectId),
    [],
  );

  /**
   * Checks out the cloud storage project.
   */
  const handleCheckOut = useCallback(
    () => {}, // cloudStorage.checkOutProject(projectId),
    [],
  );

  const handleSetOperation = useCallback((operation) => {
    // cloudStorage.setProjectOperation(projectId, operation);
  }, []);

  const isAutoSaving = useMemo(() => {
    return (
      project?.connection.autoSaveStatus === AUTO_SAVE_STATUS.STARTED &&
      project?.connection.cloudStatus === CLOUD_STATUS.OWNED
    );
  }, [project?.connection.autoSaveStatus, project?.connection.cloudStatus]);

  const hasConnection = useMemo(
    () => Boolean(project?.connection.itemId),
    [project?.connection.itemId],
  );

  const isOwned = useMemo(
    () => project?.connection.cloudStatus === CLOUD_STATUS.OWNED,
    [project?.connection.cloudStatus],
  );

  const isReadOnly = useMemo(
    () => project?.connection.cloudStatus === CLOUD_STATUS.READONLY,
    [project?.connection.cloudStatus],
  );

  const canInteractWithCloudProvider = useMemo(() => {
    return !project?.operation && project?.connection.cloudStatus === CLOUD_STATUS.OWNED;
  }, [project?.connection.cloudStatus, project?.operation]);

  const canToggleAutoSave = useMemo(() => {
    return !project?.operation;
  }, [project?.operation]);

  const canOpenInDesktop = useMemo(() => {
    return (
      canInteractWithCloudProvider &&
      isAutoSaving &&
      canPlatformOpenInDesktop() &&
      Boolean(getProjectDesktopFileScheme(project))
    );
  }, [canInteractWithCloudProvider, isAutoSaving, project]);

  const canRenameProject = useMemo(() => {
    return canInteractWithCloudProvider && isAutoSaving;
  }, [canInteractWithCloudProvider, isAutoSaving]);

  const canMoveProject = useMemo(() => {
    return canInteractWithCloudProvider && isAutoSaving;
  }, [canInteractWithCloudProvider, isAutoSaving]);

  const canShareProject = useMemo(() => {
    return (
      canInteractWithCloudProvider &&
      isAutoSaving &&
      project?.type === STORAGE_PROVIDER_KEYS.ONE_DRIVE
    );
  }, [canInteractWithCloudProvider, isAutoSaving, project?.type]);

  return useMemo(
    () => ({
      ...project,
      autoSaveStatus  : project?.autoSaveStatus,
      cloudStatus     : project?.cloudStatus,
      operation       : project?.operation,
      breadcrumbs,
      hasConnection,
      isAutoSaving,
      isOwned,
      isReadOnly,
      canInteractWithCloudProvider,
      canToggleAutoSave,
      canOpenInDesktop,
      canRenameProject,
      canMoveProject,
      canShareProject,
      move            : handleMoveProject,
      rename          : handleRenameProject,
      download        : handleDownloadProject,
      flush           : handleFlushProject,
      close           : handleCloseProject,
      share           : handleShareProject,
      checkIn         : handleCheckIn,
      checkOut        : handleCheckOut,
      openInDesktop   : handleOpenInDesktop,
      enableAutoSave  : handleEnableAutoSave,
      disableAutoSave : handleDisableAutoSave,
      toggleAutoSave  : handleToggleAutoSave,
      getBreadcrumbs  : handleGetBreadcrumbs,
      getCheckoutUser : handleGetCheckoutUser,
      verifyConnection: handleVerifyConnection,
      sync            : handleSyncProject,
      setOperation    : handleSetOperation,
      clearOperation  : () => handleSetOperation(null),
    }),
    [
      project,
      breadcrumbs,
      hasConnection,
      isAutoSaving,
      isOwned,
      isReadOnly,
      canInteractWithCloudProvider,
      canToggleAutoSave,
      canOpenInDesktop,
      canRenameProject,
      canMoveProject,
      canShareProject,
      handleMoveProject,
      handleRenameProject,
      handleDownloadProject,
      handleFlushProject,
      handleCloseProject,
      handleShareProject,
      handleCheckIn,
      handleCheckOut,
      handleOpenInDesktop,
      handleEnableAutoSave,
      handleDisableAutoSave,
      handleToggleAutoSave,
      handleGetBreadcrumbs,
      handleGetCheckoutUser,
      handleVerifyConnection,
      handleSyncProject,
      handleSetOperation,
    ],
  );
};
