import type { ProjectStoreSlice, StoreProject } from './types';
import Storage from '../storage';

const STORAGE_PROJECTS_KEY = 'mtb-cloud-storage-projects';

class ProjectStore {
  #storage = new Storage();

  private migrateOldStorage() {
    // TODO
  }

  constructor() {
    this.migrateOldStorage();
  }

  private getSlice(): ProjectStoreSlice {
    return this.#storage.getItem(STORAGE_PROJECTS_KEY) || {};
  }

  private setSlice(slice: ProjectStoreSlice) {
    this.#storage.setItem(STORAGE_PROJECTS_KEY, slice);
  }

  getProject(id: string): StoreProject | null {
    return this.getSlice()[id];
  }

  setProject(id: string, project: StoreProject) {
    const slice = this.getSlice();
    slice[id] = project;
    this.setSlice(slice);
  }

  removeProject(id: string) {
    const slice = this.getSlice();
    if (id in slice) {
      delete slice[id];
    }
    this.setSlice(slice);
  }

  getProjects(): Record<string, StoreProject> {
    return this.getSlice();
  }
}

const projectStore = new ProjectStore();

export default projectStore;
