import type { PlaneId } from '../types';
import { LoggerClient } from '../../clients';
import IntegratedPlatformModule from './integrated';
import StaticPlatformModule from './static';

class PlatformModule {
  #logger = LoggerClient.createNamedLogger('PlatformModule');
  #integratedPlatformModules = new Map<PlaneId, IntegratedPlatformModule>();

  /**
   * Gets the singleton StaticPlatformModule.
   * @returns {StaticPlatformModule} The StaticPlatformModule instance.
   */
  getStaticPlatformModule() {
    return StaticPlatformModule;
  }

  /**
   * Gets or creates an IntegratedPlatformModule for the given planeId.
   * @param planeId - The planeId to get or create the IntegratedPlatformModule for.
   * @returns {IntegratedPlatformModule} The IntegratedPlatformModule instance.
   */
  getOrCreateIntegratedPlatformModule(planeId: PlaneId) {
    if (this.#integratedPlatformModules.has(planeId)) {
      return this.#integratedPlatformModules.get(planeId) as IntegratedPlatformModule;
    }

    const integratedPlatformModule = new IntegratedPlatformModule(planeId);
    this.#integratedPlatformModules.set(planeId, integratedPlatformModule);
    this.#logger.log(`Created Integrated ${integratedPlatformModule.plane.module} Platform Module`, integratedPlatformModule);
    return integratedPlatformModule;
  }
}

export const platformModule = new PlatformModule();
export default platformModule;
