import { useMemo } from 'react';
import { useMenu } from '@mtb/ui';
import { useNewCloudStorageProject } from '../../hooks';
import { StorageProjectMenuContext, initialState } from './context';

/**
 * @param {import('@').StorageProjectMenuContext & { children?: import('react').ReactNode }} props
 */
export const StorageProjectMenu = ({
  projectId,
  onFlush,
  onReopenProject,
  onChange,
  children,
}) => {
  const menuProps = useMenu({ id: 'storage-project' });
  const project = useNewCloudStorageProject(projectId);

  const values = useMemo(() => {
    return {
      ...initialState,
      project,
      menuProps,
      onFlush,
      onReopenProject,
      onChange,
    };
  }, [project, menuProps, onFlush, onReopenProject, onChange]);

  return (
    <StorageProjectMenuContext.Provider value={values}>
      {children}
    </StorageProjectMenuContext.Provider>
  );
};

export default StorageProjectMenu;
